import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Form, Container, Row, Col, Card } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Header from '../components/Header';
import { uploadPostAndCreateChatRoom } from '../services/FirestoreUtil';
import { useSelector } from 'react-redux';
import ConfirmationModal from '../components/ConfirmationModal';
import { modules } from '../components/ReactQuill';

const StyledContainer = styled(Container)`
  padding-top: 80px;
`;

const CategorySelect = styled(Form.Select)`
  width: 100%;
  margin-bottom: 1rem;
`;

const StyledReactQuill = styled(ReactQuill)`
  .ql-container {
    height: 500px;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    .ql-container {
      height: 300px;
    }
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 10px;
`;

const CancelButton = styled(Button)`

  margin-top: 10px;

`;

const CreatePost = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);
  const [maxSpeakers, setMaxSpeakers] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting:', { title, content, category });

    if (userInfo) {
      const { nickname, userId } = userInfo;
      const { postId, chatRoomId } = await uploadPostAndCreateChatRoom(
        title,
        content,
        category,
        nickname,
        userId,
        maxSpeakers
      );

      if (postId && chatRoomId) {
        console.log(`게시물과 채팅방이 성공적으로 업로드되었습니다. 게시물 ID: ${postId}, 채팅방 ID: ${chatRoomId}`);
        navigate(`/posts/${postId}`);
      } else {
        console.log('게시물 및 채팅방 업로드에 실패했습니다.');
      }
    } else {
      console.log('사용자 정보가 없습니다. 로그인 상태를 확인하세요.');
    }
  };

  const handleCancel = () => {
    setShowCancelModal(true);
  };

  const confirmCancel = () => {
    navigate('/');
  };

  const handleTitleChange = (e) => {
    const input = e.target.value;
    if (input.length > 40) {
      setTitleError(true);
      setTitle(input.slice(0, 40));  // 20자를 초과하는 입력은 자르기
    } else {
      setTitleError(false);
      setTitle(input);
    }
  };
  
  return (
    <StyledContainer>
      <Header />
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-sm">
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="postCategory">
                  <CategorySelect value={category} onChange={(e) => setCategory(e.target.value)} required>
                    <option value="">분야를 선택하세요</option>
                    <option value="일반/자유">일반/자유</option>
                    <option value="젠더이슈">젠더이슈</option>
                    <option value="정치/사회">정치/사회</option>
                    <option value="종교">종교</option>
                    <option value="AI/과학기술">AI/과학기술</option>
                  </CategorySelect>
                </Form.Group>

                <Form.Group controlId="postTitle">
                  <Form.Control
                    type="text"
                    placeholder="제목을 입력하세요"
                    value={title}
                    onChange={handleTitleChange}
                    autoComplete="off"
                    className="mb-3"
                    required
                  />

                  {titleError && (
                    <div style={{ color: 'red', fontSize: '0.8rem', marginTop: '5px' }}>
                      최대 40자까지 입력 가능합니다.
                    </div>
                  )}
                </Form.Group>

                <Form.Group controlId="postContent">
                  <StyledReactQuill
                    theme="snow"
                    modules={modules}
                    value={content}
                    onChange={setContent}
                    placeholder="논쟁의 주제 및 규칙에 대해 설명해주세요."
                    className="mb-3"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="maxSpeakers">
                  <Form.Label>최대 발언자 인원</Form.Label>
                  <Form.Control
                    as="select"
                    value={maxSpeakers}
                    onChange={(e) => setMaxSpeakers(e.target.value)}
                    required
                    className="mb-3"
                  >
                    <option value="">선택하세요</option>
                    {[2, 3, 4, 5, 6].map((number) => (
                      <option key={number} value={number}>
                        {number}명
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <div className="d-grid">
                  <SubmitButton variant="primary" type="submit" size="lg">
                    게시
                  </SubmitButton>
                  <CancelButton variant="secondary" size="lg" onClick={handleCancel}>취소</CancelButton>
                </div>
              </Form>
              <ConfirmationModal
                show={showCancelModal}
                onClose={() => setShowCancelModal(false)}
                onConfirm={confirmCancel}
                title="취소 확인"
                body="취소하시겠습니까? 작성된 내용은 저장되지 않습니다."
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default CreatePost;