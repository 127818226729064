import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Form, FormControl, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPencilAlt, faUsers, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import styled, { keyframes } from 'styled-components';
import { db } from '../firebaseConfig';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { doc, getDoc,  } from "firebase/firestore";
import Logo from '../icons/Logo.png';
import LoginForm from '../components/LoginForm';
import { isToday } from 'date-fns';

const auth = getAuth();

// 애니메이션 정의
const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// 스타일 컴포넌트 정의
const StyledContainer = styled(Container)`
  padding-top: 2rem;
`;

const StyledSearchButton = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: #6c757d;
  transition: color 0.3s;

  &:hover {
    color: #212529;
  }
`;

const StyledPostButton = styled(Button)`
  background: linear-gradient(45deg, #212529, #343a40);
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  float: right;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 991.98px) {
    margin-top: 1rem;
  }
`;

const StyledSearchBar = styled(FormControl)`
  height: 60px;
  border-radius: 30px;
  padding-right: 50px;
  border: 2px solid #6c757d;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
  }
`;

const HorizontalRule = styled.hr`
  border: none;
  height: 2px;
  background: linear-gradient(to right, #212529, #343a40);
  margin-top: 10px;
  animation: ${gradient} 5s ease infinite;
`;

const CategoryRow = styled(Row)`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #212529;
  margin-right:-5px;
`;

const CategoryLabel = styled.span`
  background-color: #6c757d;
  color: white;
  padding: 4px 4px;
  border-radius: 4px;
  font-size: 0.8rem;

  @media (max-width: 767.98px) {
    order: 2; // 모바일 화면에서 제목 아래로 배치
    font-size: 0.7rem;
    padding: 2px 2px;
  }
`;

const CategoryCol = styled(Col)`
  white-space: nowrap;
`;

const TitleCol = styled(Col)`
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;

  @media (max-width: 767.98px) {
    display: none; // 모바일 화면에서 숨김
  }
`;

const PostTitleCol = styled(TitleCol)`
  text-align: left; // 좌측 정렬
  font-size: 16px;

  @media (max-width: 767.98px) {
    display: block; // 모바일 화면에서 보임
  }
`;

const CommentCount = styled.span`
  color: #5CACEE; // Bootstrap의 기본 테마 색상인 "primary" 색상을 사용
  font-size: 14px;
  margin-left: 3px; // 제목과 댓글 수 사이의 간격
  font-weight: bold;
`;

const WriterCol = styled(Col)`
  text-align: right;
  white-space: nowrap;
  font-size: 13px;

  @media (max-width: 767.98px) {
    position: relative;
    padding-right: 20px; // 경계선을 위한 공간 확보
    &::after {
      content: "|";
      position: absolute;
      right: 10px; // 경계선 위치 조정
      color: #ccc; // 경계선 색상
    }
  }
`;

const DateCol = styled(Col)`
  text-align: right;
  white-space: nowrap;
  font-size: 13px;
`;

const ParticipantsCol = styled(Col)`
  text-align: right;
  white-space: nowrap;
  font-size: 13px;
`;

const PostRow = styled(Row)`
  font-size: 0.8rem;
  border-bottom: 1px solid #dee2e6;
  padding: 10px 0;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;

  
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
  }

   @media (max-width: 767.98px) {
    ${PostTitleCol} {
      width: 100%;
      margin-bottom: 1px;
      order: 1;
    }

    ${CategoryCol}, ${WriterCol}, ${DateCol}, ${ParticipantsCol} {
      width: auto;
      text-align: left;
      font-size: 0.8rem;
    }

    ${CategoryCol} {
      order: 2;
      margin-right: 5px;
    }

    ${WriterCol} {
      order: 3;
      margin-left: -20px;
    }

    ${ParticipantsCol} {
      order: 4;
      padding-right: 0;
      margin-left: -15px;
      color: grey;
    }

    ${DateCol} {
      order: 5;
      margin-left: auto;
      color: grey;
    }
  }
`;

const MoreButton = styled(Button)`
  margin-top: 10px;
  margin-right: -10px;
  float: right;
  background: linear-gradient(45deg, #212529, #343a40); // 색상 일치
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  padding: 0.375rem 0.75rem; // padding 조정하여 크기 축소

  &:hover {
    background: linear-gradient(45deg, #343a40, #212529); // 색상 일치, 방향 전환으로 효과 주기
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); // 호버 시 그림자 강조
  }

  &:active {
    background: linear-gradient(45deg, #343a40, #212529) !important; // 활성화 시 색상 일치
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2) !important; // 활성화 시 그림자 강조
  }
`;


const StyledDropdownToggle = styled(Dropdown.Toggle)`
  display: flex;
  align-items: center;

  
  &::after {
    content: '';
    order: -1; // 아이콘을 왼쪽으로 이동
    margin-right: 8px; // 아이콘과 텍스트 사이 간격
  }
`;

const MobileHiddenCol = styled(Col)`
  text-align: right;
  white-space: nowrap;

  @media (max-width: 767.98px) {
    display: none; // 모바일 화면에서 숨김
  }
`;

const GroupIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  font-size: 0.8rem;
`;

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [category, setCategory] = useState('');

  const handlePostButtonClick = () => {
    if (user) {
      navigate('/create-post');
    } else {
      alert('로그인 하십시오.');
    }
  };

  const [user, setUser] = useState(null);
  const [nickname, setNickname] = useState('');

  // 게시물 작성일을 형식화하는 함수
  const formatDate = (timestamp) => {
    const date = timestamp.toDate(); // Firestore timestamp를 JavaScript Date 객체로 변환
    if (isToday(date)) {
      return date.toLocaleTimeString([], { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }); // 24시간 형식으로 당일 게시글 시간과 분 표시
    } else {
      // 월과 일 사이의 공백을 제거하고, 문자열 끝의 점을 제거
      return date.toLocaleDateString('ko-KR', { month: '2-digit', day: '2-digit' })
                 .replace(/\.\s/g, ".") // 모든 월과 일 사이의 점 뒤 공백 제거
                 .replace(/\.$/, "");   // 문자열 끝의 점 제거
    }
  };  

  
  useEffect(() => {
    fetchPosts();  // 컴포넌트가 마운트될 때 초기 데이터 로드
  }, []);
  
  const handleSearch = () => {
    const filtered = posts.filter(
      (post) =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.content.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPosts(filtered);
  };

  // 채팅방 정보를 가져오는 함수
  const fetchChatRoomDetails = async (postId, chatRoomId) => {
    // chatRoomId가 없는 경우 바로 "0/0" 반환
    if (!chatRoomId) {
      return "--";
    }

    const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
    const chatRoomSnap = await getDoc(chatRoomRef);
    if (chatRoomSnap.exists()) {
      const chatRoomData = chatRoomSnap.data();
      return `${chatRoomData.speakers.length}/${chatRoomData.maxSpeakers}`;
    }
    return "--"; // 채팅방 정보가 없을 경우
};

  const fetchPosts = async () => {
    setLoading(true);
    const firstQuery = query(collection(db, "posts"), orderBy("createdAt", "desc"), limit(14));
    const documentSnapshots = await getDocs(firstQuery);

    const postsArray = await Promise.all(documentSnapshots.docs.map(async doc => {
      const postData = doc.data();
      const participantsInfo = await fetchChatRoomDetails(doc.id, postData.chatRoomId);
      return {
        id: doc.id,
        participants: participantsInfo,
        ...postData
      };
    }));

    const pinnedPosts = postsArray.filter(post => post.pinned === true);
    const nonPinnedPosts = postsArray.filter(post => !post.pinned);
    const sortedPosts = [...pinnedPosts, ...nonPinnedPosts];

    setPosts(sortedPosts);
    setLoading(false);

    // 여기서 lastVisible 설정을 변경하지 않고 원래의 문서 위치를 유지합니다.
    if (documentSnapshots.docs.length < 14) {
      setLastVisible(null);
    } else {
      setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
    }
  };
    
  const fetchMorePosts = async () => {
    if (lastVisible && !loading) {
      setLoading(true);
      const nextQuery = query(collection(db, "posts"), orderBy("createdAt", "desc"), startAfter(lastVisible), limit(14));
      const documentSnapshots = await getDocs(nextQuery);
      const nextPostsArray = await Promise.all(documentSnapshots.docs.map(async doc => {
        const postData = doc.data();
        const participantsInfo = await fetchChatRoomDetails(doc.id, postData.chatRoomId);
        return {
          id: doc.id,
          participants: participantsInfo,
          ...postData
        };
      }));
  
      setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
      setPosts(prevPosts => [...prevPosts, ...nextPostsArray]);
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setNickname(docSnap.data().nickname);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (category === '') {
      setFilteredPosts(posts);
    } else {
      const filtered = posts.filter(post => post.category === category);
      setFilteredPosts(filtered);
    }
  }, [category, posts]);
  
  

  return (
    <>
      <StyledContainer>
        <Row className="justify-content-center align-items-center my-4">
          <Col xs="auto">
            <img src={Logo} alt="DatNon Logo" className="img-fluid" style={{ maxWidth: '150px' }} />
          </Col>
          <Col md={6}>
            <Form className="position-relative" onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}>
              <StyledSearchBar
                type="text"
                placeholder="검색어를 입력해주세요"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoComplete="off" 
              />
              <StyledSearchButton type="submit">
                <FontAwesomeIcon icon={faSearch} />
              </StyledSearchButton>
            </Form>
          </Col>
        </Row>
  
        <Row className="d-lg-none">
          <Col>
            <LoginForm user={user} nickname={nickname} />
          </Col>
        </Row>
  
        <Row>
          <Col lg={9}>
            <Row>
              <Col className="text-right">
                <StyledPostButton onClick={handlePostButtonClick}>
                  <FontAwesomeIcon icon={faPencilAlt} /> 글작성
                </StyledPostButton>
              </Col>
            </Row>
            <HorizontalRule />
            <CategoryRow>
            <CategoryCol xs={2}>
            <Dropdown>
              <StyledDropdownToggle  variant="light" id="dropdown-basic" size="sm">
                {category === '' ? '카테고리' : category}
              </StyledDropdownToggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setCategory('')}>전체</Dropdown.Item>
                <Dropdown.Item onClick={() => setCategory('일반/자유')}>일반/자유</Dropdown.Item>
                <Dropdown.Item onClick={() => setCategory('젠더이슈')}>젠더이슈</Dropdown.Item>
                <Dropdown.Item onClick={() => setCategory('정치/사회')}>정치/사회</Dropdown.Item>
                <Dropdown.Item onClick={() => setCategory('종교')}>종교</Dropdown.Item>
                <Dropdown.Item onClick={() => setCategory('AI/과학기술')}>AI/과학기술</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </CategoryCol>
              <TitleCol xs={6}>제목</TitleCol>
              <MobileHiddenCol  xs={2}>글쓴이</MobileHiddenCol>
              <MobileHiddenCol xs={1}>작성일</MobileHiddenCol>  
              <MobileHiddenCol xs={1}>방인원</MobileHiddenCol>
            </CategoryRow>
            <HorizontalRule />
            <div style={{ padding: '10px' }}>
            {filteredPosts.length > 0 ? (
              filteredPosts.map((post) => (
                <PostRow
                  key={post.id}
                  style={{ backgroundColor: post.pinned ? '#ffefba' : 'transparent' }}
                  onClick={() => navigate(`/posts/${post.id}`)}
                >
                  <CategoryCol xs={2}><CategoryLabel>{post.category}</CategoryLabel></CategoryCol>
                  <PostTitleCol xs={6}>
                  {post.pinned && <FontAwesomeIcon icon={faThumbtack} style={{ marginRight: '5px' }} />}
                  {post.title}
                  {post.commentCount > 0 && <CommentCount>({post.commentCount})</CommentCount>}
                  </PostTitleCol>
                  <WriterCol xs={2}>{post.author}</WriterCol>
                  <DateCol xs={1}>{formatDate(post.createdAt)}</DateCol>
                  <ParticipantsCol xs={1}><GroupIcon icon={faUsers} />{post.participants}</ParticipantsCol>
                </PostRow>
              ))
            ) : (
              <p style={{ textAlign: 'center' }}>결과가 없습니다.</p>
            )}
            {loading && (
              <MoreButton variant="secondary" disabled>Loading...</MoreButton>
            )}
            {
              !loading && lastVisible ? (
                <MoreButton onClick={fetchMorePosts}>더보기</MoreButton>
              ) : null
            }
          </div>
          </Col>
          <Col lg={3} className="d-none d-lg-block">
            <LoginForm user={user} nickname={nickname} />
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
};

export default Home;