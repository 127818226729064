import React, { useState } from 'react';
import { Modal, Button, Form, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// 스타일 컴포넌트
const StyledCard = styled(Card)`
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StyledLoginButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background: #343a40;
  border: none;
  border-radius: 2rem;
  padding: 12px;
  font-size: 1.1rem;
  font-weight: bold;
  transition: background 0.3s;

  &:hover {
    background: #495057;
  }

  &:active {
    background: #212529 !important;
  }
`;

const CenteredModalHeader = styled(Modal.Header)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none;
  padding: 2rem 1rem 1rem;
`;

const ModalTitle = styled(Modal.Title)`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-left: 20px;
`;

const StyledModalBody = styled(Modal.Body)`
  padding: 1rem 2rem 2rem;
`;

const StyledFormGroup = styled(Form.Group)`
  margin-bottom: 15px;  // 폼 그룹 간 간격 추가
`;

const FormControl = styled(Form.Control)`
  border-radius: 1rem;
  padding: 12px;
  font-size: 1.1rem;
`;

const StyledModal = styled(Modal)`
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 350px;
    }
  }
`;


const SignUpForm = ({ show, handleClose, signUp }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();  // 폼 제출에 의한 페이지 새로고침 방지
    setError('');  // 회원가입 시도 전에 이전 에러 메시지를 초기화
    try {
      const success = await signUp(email, password);
      if (success) {
        console.log('User signed up');
        handleClose();  // 회원가입 성공 후 모달 닫기
      }
    } catch (error) {
      console.error('Signup error:', error);
      setError(error.message);  // `signUp` 함수에서 throw된 에러 메시지를 UI에 표시
    }
  };
  
  

  return (
    <StyledModal show={show} onHide={handleClose} size="sm" centered>
      <StyledCard>
        <CenteredModalHeader closeButton>
          <ModalTitle>회원가입</ModalTitle>
        </CenteredModalHeader>
        <StyledModalBody>
          <Form onSubmit={handleSignUp}>
            <StyledFormGroup controlId="formBasicEmail">
              <FormControl
                type="email"
                placeholder="이메일"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </StyledFormGroup>
            <StyledFormGroup controlId="formBasicPassword">
              <FormControl
                type="password"
                placeholder="비밀번호"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </StyledFormGroup>
            {error && <div style={{ color: 'red', fontSize: '0.8em', marginTop: '10px' }}>{error}</div>}
            <StyledLoginButton variant="primary" type="submit">
              가입하기
            </StyledLoginButton>
          </Form>
        </StyledModalBody>
      </StyledCard>
    </StyledModal>
  );
};

export default SignUpForm;