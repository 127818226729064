// src/features/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null, // 사용자 정보 초기 상태는 null
  },
  reducers: {
    setUser: (state, action) => {
      state.userInfo = {
        ...action.payload,
        isAdmin: action.payload.isAdmin || false // 관리자 정보 추가
      };
    },
    clearUser: (state) => {
      state.userInfo = null; // 사용자 정보 초기화
    },
    updateNickname: (state, action) => {
      if (state.userInfo) {
        state.userInfo.nickname = action.payload.nickname;
      }
    },
  },
});

export const { setUser, clearUser, updateNickname } = userSlice.actions;

export default userSlice.reducer;
