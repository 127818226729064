import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Form, Button  } from 'react-bootstrap';
import { signUp, signIn, signInWithGoogle, logout } from '../services/FirebaseAuth';
import SignInIcon from '../icons/SignIn.png';
import styled from 'styled-components';
import { ReactComponent as NotiReadIcon } from '../icons/NotiRead.svg';
import { ReactComponent as NotiUnreadIcon } from '../icons/NotiUnread.svg';
import { db } from '../firebaseConfig';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { deleteNotification } from '../features/notifications/deleteNotification'
import SignUpForm from './SignUpForm';

// 스타일 컴포넌트 정의
const StyledLoginButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
  background: #343a40;
  border: none;
  padding: 8px;
  border-color: black;
  border-radius: 2rem;

  &:hover {
    background: #495057;
  }
  &:active {
    background: #212529 !important; // 클릭 시 배경색을 그레이로 설정
  }
`;

const StyledCard = styled(Card)`
border-radius: 0.25rem 0.25rem 0 0`

const StyledGoogleLoginButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  background: #343a40;
  border: none;
  padding: 8px;
  border-radius: 2rem;
  color: white; // 텍스트 색상 설정
  font-size: 1rem; // 텍스트 크기 설정

  &:hover {
    background: #495057;
  }
  &:active {
    background: #212529 !important; // 클릭 시 배경색 변경
  }
`;

const Logo = styled.img`
  height: 24px; // 로고의 높이
  width: auto; // 로고의 너비는 자동으로 설정
  margin-right: 8px; // 로고와 텍스트 사이의 간격
  margin-left: -20px;
`;

const StyledLogoutText = styled.span`
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
  float: right;
  &:hover {
    text-decoration: underline;
  }
  font-size: 13px;
`;

const StyledProfileText = styled(StyledLogoutText)`
  margin-right: 10px; // 우측 여백 추가
`;

const StyledNotificationIcon = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-right: auto;
  padding-top: 5px;
  color: black; // 기본 아이콘 색상

  &:hover::after {
    content: ""; // 가상 요소의 존재를 위해 필요
    position: absolute;
    top: -5px; // 아이콘의 주변으로 확장될 영역 설정
    left: -5px;
    right: -5px;
    bottom: -5px;
    background-color: rgba(224, 224, 224, 0.5); // 배경색을 반투명으로 설정
    border-radius: 50%; // 원형 배경
    z-index: -1; // 아이콘 뒤에 배치
  }

  &:active {
    transform: scale(0.9); // 클릭 시 축소 효과
  }

  svg {
    fill: currentColor; // SVG 색상을 부모 요소의 색상에 맞춤
  }
`;


const NotificationItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;

  &:first-child {
    border-top: none;
  }
  &:hover {
    color: #666;
  }
`;

const DateText = styled.span`
  font-size: 0.8em; // 텍스트 크기를 줄입니다.
  color: gray; // 회색으로 표시
  float: right; // 텍스트를 우측 정렬합니다.
  padding-right: 10px; // 우측 패딩 추가
`;

const NotificationsPanel = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  max-height: 200px;
  overflow-y: auto;
  font-size: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const StyledFormGroup = styled(Form.Group)`
  margin-bottom: 10px;  // 폼 그룹 간 간격 추가
  margin-top: 10px;
`;

const FormControl = styled(Form.Control)`
  border-radius: 1rem;
  padding: 12px;
  font-size: 1.1rem;
`;

const BoldCardTitle = styled(Card.Title)`
  font-weight: bold; // 볼드체 적용
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.4rem;
`;

const LoginForm = ({ user, nickname }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('')
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [showSignUp, setShowSignUp] = useState(false);

  useEffect(() => {
    if (!user) {
        setShowNotifications(false);
        return;
      }

      const notificationsRef = collection(db, `users/${user.uid}/notifications`);
      const q = query(notificationsRef, where("read", "==", false));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const loadedNotifications = [];
        querySnapshot.forEach(doc => {
          loadedNotifications.push({ id: doc.id, ...doc.data() });
        });
        setNotifications(loadedNotifications);
      });
      return () => unsubscribe();
  }, [user]);

  const handleNotificationClick = async (notification) => {
    // 알림 삭제
    await deleteNotification(user.uid, notification.id);
    // 알림에 설정된 대상 경로로 이동
    navigate(notification.destinationPath);
  };
  
  const hasUnreadNotifications = notifications.length > 0;

  const formatDate = (timestamp) => {
    return new Date(timestamp.seconds * 1000).toLocaleString('ko-KR', {
      month: 'long', // 'long' 옵션은 월을 긴 이름으로 표시합니다.
      day: 'numeric'
    });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError('');  // 로그인 시도 전에 이전 에러 메시지를 초기화
    try {
      const result = await signIn(email, password);
      if (result === true) {
        console.log('User signed in');  // 로그인 성공 시 콘솔에 로그
      } else {
        setError(result);  // 에러 메시지를 상태로 설정하여 UI에 표시
      }
    } catch (error) {
      setError('로그인 처리 중 예상치 못한 문제가 발생했습니다.');  // 예외 상황에 대한 에러 메시지 설정
      console.error('Signin error:', error.message);  // 콘솔에 예외 로그 기록
    }
  };
  
  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      console.log('Google User signed in');
    } catch (error) {
      console.error('Google Signin error:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      console.log('로그아웃 성공');
    } catch (error) {
      console.error('로그아웃 실패:', error);
    }
  };

  const handleGoToProfile = () => {
    if (user) {
        navigate(`/profile/${user.uid}`);
      }
  };

  return (
    <>
    <StyledCard>
      <Card.Body>
        {user ? (
          <>
            {nickname}님 환영합니다. <br />
            <StyledNotificationIcon onClick={() => setShowNotifications(!showNotifications)}>
              {hasUnreadNotifications ? <NotiUnreadIcon /> : <NotiReadIcon />}
            </StyledNotificationIcon>
            <StyledLogoutText onClick={handleLogout}>로그아웃</StyledLogoutText>
            <StyledProfileText onClick={handleGoToProfile}>프로필</StyledProfileText>
          </>
        ) : (
          <>
            <BoldCardTitle>로그인</BoldCardTitle>
            <Form onSubmit={handleSignIn}>
              <StyledFormGroup controlId="formBasicEmail">
                <FormControl
                  type="email"
                  placeholder="이메일"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </StyledFormGroup>

              <Form.Group controlId="formBasicPassword">
                <FormControl
                  type="password"
                  placeholder="비밀번호"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              {error && <div style={{ color: 'red', fontSize: '0.8em', marginTop: '10px' }}>{error}</div>}
              
              <StyledLoginButton variant="primary" type="submit">
                로그인
              </StyledLoginButton>
            </Form>
            <StyledGoogleLoginButton onClick={handleGoogleSignIn}>
              <Logo src={SignInIcon} alt="Google Sign-In" />
              구글 로그인
            </StyledGoogleLoginButton>
            <StyledLoginButton variant="secondary" onClick={() => setShowSignUp(true)}>회원가입</StyledLoginButton>
            <SignUpForm
              show={showSignUp}
              handleClose={() => setShowSignUp(false)}
              signUp={signUp} // SignUpForm에서 필요한 signUp 함수를 전달합니다.
            />
          </>
        )}
      </Card.Body>
    </StyledCard>
    {showNotifications && (
      <NotificationsPanel>
        {notifications.length > 0 ? (
          notifications.map(notification => (
            <NotificationItem key={notification.id} onClick={() => handleNotificationClick(notification)}> 
              {notification.message}
              <DateText>{formatDate(notification.createdAt)}</DateText>
            </NotificationItem>
          ))
        ) : (
          <div style={{ padding: '10px', textAlign: 'center' }}>알림이 없습니다.</div>
        )}
      </NotificationsPanel>
    )}
  </>
  );
};

export default LoginForm;