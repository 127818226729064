import { doc, deleteDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

// 알림 삭제 함수
export const deleteNotification = async (userId, notificationId) => {
  try {
    // Firestore에서 해당 알림 문서 참조
    const notificationRef = doc(db, `users/${userId}/notifications`, notificationId);
    // 문서 삭제
    await deleteDoc(notificationRef);
    console.log('Notification deleted successfully');
  } catch (error) {
    console.error('Failed to delete notification:', error);
  }
};

export const deleteChatRoomNotifications = async (userId, chatRoomId) => {
  try {
    const notificationsRef = collection(db, `users/${userId}/notifications`);
    const q = query(notificationsRef, where("chatRoomId", "==", chatRoomId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });
    console.log('All notifications for chatRoomId deleted successfully');
  } catch (error) {
    console.error('Failed to delete notifications for chatRoom:', error);
  }
};