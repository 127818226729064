import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const Brand = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size:150%;
  &:hover {
    color: #fff;
  }
`;

const Header = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand as={Brand} to="/">DatNon</Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
