// ChatService.js
import { db } from '../../firebaseConfig';
import { collection, addDoc, doc, serverTimestamp, updateDoc, arrayUnion, onSnapshot, query, orderBy, arrayRemove, getDoc } from 'firebase/firestore';


// 메시지 추가
export const addMessageToChatRoom = async (postId, chatRoomId, message, nickname) => {
    try {
      await addDoc(collection(db, "posts", postId, "chatrooms", chatRoomId, "messages"), {
        text: message.text,
        authorId: message.authorId,
        nickname: nickname,
        type: 'message',
        createdAt: serverTimestamp()
      });
      console.log("Message added to chatroom");
    } catch (e) {
      console.error("Error adding message to chatroom: ", e);
    }
  };

// 알림 메시지 추가
export const addNotificationToChatRoom = async (postId, chatRoomId, text) => {
  try {
    await addDoc(collection(db, "posts", postId, "chatrooms", chatRoomId, "messages"), {
      text: text,
      type: 'notification',  // 알림 타입 지정
      createdAt: serverTimestamp()
    });
    console.log("Notification added to chatroom");
  } catch (e) {
    console.error("Error adding notification to chatroom: ", e);
  }
}

// 채팅방 메시지 실시간 수신 함수
export const subscribeToChatRoomMessages = (postId, chatRoomId, onReceiveMessage) => {
const messagesRef = collection(db, "posts", postId, "chatrooms", chatRoomId, "messages");
const queryMessages = query(messagesRef, orderBy("createdAt", "desc")); // 최신순으로 정렬

return onSnapshot(queryMessages, (snapshot) => {
const messagesList = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
}));
onReceiveMessage(messagesList.reverse()); // 배열을 뒤집어서 최신 메시지가 마지막에 오도록 함
});
};

// 발언자 추가
export const addSpeakerToChatRoom = async (postId, chatRoomId, userId) => {
  const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
  const chatRoomDoc = await getDoc(chatRoomRef);

  if (!chatRoomDoc.exists()) {
    console.error("ChatRoom does not exist.");
    return;
  }

  const chatRoomData = chatRoomDoc.data();
  const currentSpeakersLength = chatRoomData.speakers.length;

  // 최대 speaker 수를 초과하는지 확인
  if (currentSpeakersLength < chatRoomData.maxSpeakers) {
    // 현재 speaker 수가 최대 수 미만이면 speaker 추가
    try {
      await updateDoc(chatRoomRef, {
        speakers: arrayUnion(userId)
      });
      console.log("Speaker added to chatroom.");
      
      // 사용자가 참여한 게시글 ID를 사용자 프로필에 추가
      await addPostIdToUser(userId, postId);
    } catch (e) {
      console.error("Error adding speaker to chatroom or updating user profile: ", e);
    }
  } else {
    // 최대 speaker 수를 초과한 경우
    console.log("Max speakers limit reached. Cannot add more speakers.");
  }
};

//프로필 데이터에 현재 참여중인 채팅룸이 속한 게시글 id 추가
export const addPostIdToUser = async (userId, postId) => {
  const userDocRef = doc(db, "users", userId);
  try {
    // 사용자 프로필 문서에 참여한 게시글 ID 추가
    await updateDoc(userDocRef, {
      participatedPosts: arrayUnion(postId)
    });
    console.log("Post ID added to user profile.");
  } catch (error) {
    console.error("Error adding post ID to user profile:", error);
  }
};

//프로필 데이터에 현재 참여중인 채팅룸이 속한 게시글 id 제거
export const removePostIdFromUser = async (userId, postId) => {
  const userDocRef = doc(db, "users", userId);
  try {
    // 사용자 프로필 문서에서 참여한 게시글 ID 제거
    await updateDoc(userDocRef, {
      participatedPosts: arrayRemove(postId)
    });
    console.log("Post ID removed from user profile.");
  } catch (error) {
    console.error("Error removing post ID from user profile:", error);
  }
};


// 관전자 추가
export const addViewerToChatRoom = async (postId, chatRoomId, userId) => {
  const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
  try {
    await updateDoc(chatRoomRef, {
      viewers: arrayUnion(userId)
    });
    console.log("Viewer added to chatroom");
  } catch (e) {
    console.error("Error adding viewer to chatroom: ", e);
  }
};

export const removeSpeakerFromChatRoom = async (postId, chatRoomId, userId) => {
  const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
  try {
    // 채팅룸 문서에서 스피커 제거
    await updateDoc(chatRoomRef, {
      speakers: arrayRemove(userId)
    });
    console.log("Speaker removed from chatroom");

    // 사용자 프로필에서 참여한 게시글 ID 제거
    await removePostIdFromUser(userId, postId);
  } catch (e) {
    console.error("Error removing speaker from chatroom: ", e);
  }
};

  // 채팅방에 실시간 발언자 추가
  export const addLiveSpeaker = async (postId, chatRoomId, userId) => {
    const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
    try {
      const chatRoomDoc = await getDoc(chatRoomRef);
      if (!chatRoomDoc.exists()) {
        console.error("ChatRoom does not exist.");
        return;
      }
  
      const chatRoomData = chatRoomDoc.data();
      if (chatRoomData.speakers.includes(userId)) {
        // speakers 배열에 userId가 존재할 경우에만 liveSpeakers에 추가
        await updateDoc(chatRoomRef, {
          liveSpeakers: arrayUnion(userId)
        });
        console.log("Live speaker added to chatroom");
      } else {
        console.error("User is not a speaker in this chatroom");
      }
    } catch (e) {
      console.error("Error adding live speaker to chatroom: ", e);
    }
  };  

// 채팅방에서 실시간 발언자 제거
export const removeLiveSpeaker = async (postId, chatRoomId, userId) => {
  const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
  try {
    await updateDoc(chatRoomRef, {
      liveSpeakers: arrayRemove(userId)
    });
    console.log("Live speaker removed from chatroom");
  } catch (e) {
    console.error("Error removing live speaker from chatroom: ", e);
  }
};

export const removeViewerFromChatRoom = async (postId, chatRoomId, userId) => {
    const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
    try {
      await updateDoc(chatRoomRef, {
        viewers: arrayRemove(userId)
      });
      console.log("Viewer removed from chatroom");
    } catch (e) {
      console.error("Error removing viewer from chatroom: ", e);
    }
  };
  
export const setSpeakerLimit = async (postId, chatRoomId, limit) => {
  const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
  try {
    // 발언자 인원 제한을 설정하는 로직
    // limit 값은 2에서 6 사이여야 합니다.
    if(limit >= 2 && limit <= 6) {
      await updateDoc(chatRoomRef, {
        maxSpeakers: limit
      });
      console.log(`Speaker limit set to ${limit}`);
    } else {
      console.error("Speaker limit must be between 2 and 6");
    }
  } catch (e) {
    console.error("Error setting speaker limit: ", e);
  }
};