import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { db } from '../firebaseConfig';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Header from '../components/Header';
import styled from 'styled-components';
import LoadingScreen from '../components/LoadingScreen';
import ConfirmationModal from '../components/ConfirmationModal';
import { modules } from '../components/ReactQuill';
import { updatePost } from '../services/FirestoreUtil';

const StyledContainer = styled(Container)`
  padding-top: 80px;
`;

const StyledReactQuill = styled(ReactQuill)`
  .ql-container {
    height: 500px;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    .ql-container {
      height: 300px;
    }
  }
`;

const CategorySelect = styled(Form.Select)`
  width: 100%;
  margin-bottom: 1rem;
`;

const StyledFormGroup = styled(Form.Group)`
  margin-bottom: 10px;
`;

const EditPost = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const currentUser = useSelector((state) => state.user.userInfo);
  const userId = currentUser ? currentUser.userId : null;

  useEffect(() => {
    const fetchPostData = async () => {
      const docRef = doc(db, "posts", postId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const postData = docSnap.data();
        setTitle(postData.title);
        // 서버로부터 받은 HTML에서 이미지 URL이 올바르게 img 태그로 변환되었는지 확인
        const convertedContent = convertUrlsToImgTags(postData.content);
        setContent(convertedContent); // Quill 에디터에 로드할 상태 설정
        setCategory(postData.category);
      } else {
        navigate('/');
      }
      setLoading(false);
    };
    fetchPostData();
  }, [postId, navigate]);
  
  // 이미지 URL을 img 태그로 변환하는 함수
  const convertUrlsToImgTags = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    doc.querySelectorAll('img').forEach(img => {
      const src = img.getAttribute('src');
      if (src && !src.startsWith('data:image')) {
        // 이미지 URL을 Quill에서 인식할 수 있는 형식으로 변환
        img.setAttribute('src', src);
      }
    });
    return doc.body.innerHTML;
  };

  const handleTitleChange = (e) => {
    const input = e.target.value;
    if (input.length > 40) {
      setTitleError(true);
      setTitle(input.slice(0, 40));  // 40자를 초과하는 입력은 자르기
    } else {
      setTitleError(false);
      setTitle(input);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title.trim() || !content.trim() || !category) {
      alert('모든 필수 필드를 채워주세요.'); // 간단한 경고 메시지
      return;
    }

    if (!userId) {
      alert("로그인이 필요합니다.");
      return;
    }

    const postRef = doc(db, "posts", postId);
    try {
      // 먼저 제목과 카테고리 업데이트
      await updateDoc(postRef, {
        title: title,
        category: category,
      });
      // 콘텐츠 및 이미지 처리
      await updatePost(postId, content, userId);
      navigate(`/posts/${postId}`);
    } catch (error) {
      console.error("Failed to update post:", error);
      alert("게시글 수정에 실패했습니다.");
    }
  };
  

  const handleContentChange = (content, delta, source, editor) => {
  if (source === 'user') {
    const html = editor.getHTML ? editor.getHTML() : editor.root.innerHTML;
    setContent(html);
  }
};

  const handleCancel = () => {
    setShowCancelModal(true);
  };

  const confirmCancel = () => {
    navigate(`/posts/${postId}`);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <StyledContainer>
      <Header />
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-sm">
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <StyledFormGroup controlId="postCategory">
                  <CategorySelect
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    required
                    className="mb-3"
                  >
                    <option value="">분야를 선택하세요</option>
                    <option value="일반/자유">일반/자유</option>
                    <option value="젠더이슈">젠더이슈</option>
                    <option value="정치/사회">정치/사회</option>
                    <option value="종교">종교</option>
                    <option value="AI/과학기술">AI/과학기술</option>
                  </CategorySelect>
                </StyledFormGroup>
  
                <StyledFormGroup controlId="postTitle">
                  <Form.Control
                    type="text"
                    placeholder="제목을 입력하세요"
                    value={title}
                    onChange={handleTitleChange}
                    autoComplete="off"
                    className="mb-3"
                    required
                    maxLength="40"
                  />
                  {titleError && (
                    <div style={{ color: 'red', fontSize: '0.8rem', marginTop: '5px' }}>
                      최대 40자까지 입력 가능합니다.
                    </div>
                  )}
                </StyledFormGroup>
  
                <StyledFormGroup controlId="postContent">
                  <StyledReactQuill
                    theme="snow"
                    modules={modules}
                    value={content}
                    onChange={handleContentChange}
                    required
                    className="mb-3"
                  />
                </StyledFormGroup>
  
                <ConfirmationModal
                  show={showCancelModal}
                  onClose={() => setShowCancelModal(false)}
                  onConfirm={confirmCancel}
                  title="취소 확인"
                  body="취소하시겠습니까? 변경된 내용은 저장되지 않습니다."
                />
  
                <div className="d-grid gap-2">
                  <Button variant="primary" type="submit" size="lg">
                    수정
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleCancel}
                    size="lg"
                  >
                    취소
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default EditPost;