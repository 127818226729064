import { db } from '../../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { addPostIdToUser } from './ChatService';

// 채팅방 생성
export const createChatRoom = async (postId, userId, maxSpeakers) => {
  try {
    const chatRoomRef = await addDoc(collection(db, "posts", postId, "chatrooms"), {
      speakers: [userId], // 채팅방 생성자를 스피커로 자동 추가
      liveSpeakers: [], // 실시간 스피커 목록
      viewers: [], // 관람객 목록
      host: userId, // 호스트 사용자 ID
      maxSpeakers: maxSpeakers, // 최대 스피커 수
    });
    console.log("ChatRoom created with ID: ", chatRoomRef.id);

    // 사용자가 참여한 게시글 ID를 사용자 프로필에 추가
    await addPostIdToUser(userId, postId);

    return chatRoomRef.id;
  } catch (e) {
    console.error("Error creating chatroom: ", e);
    return null;
  }
};