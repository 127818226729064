// src/pages/DebateDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import DOMPurify from 'dompurify';
import { Button, Card } from 'react-bootstrap';
import { deletePost } from '../services/FirestoreUtil';
import { useSelector } from 'react-redux';
import AddComment from '../services/AddComment';
import MobileHeader from '../components/MobileHeader';
import ArrowBack from '../icons/ArrowBack.svg';
import ConfirmationModal from '../components/ConfirmationModal';
import LoadingScreen from '../components/LoadingScreen';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit, faTrashAlt, faEllipsisV, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';


const Container = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 15px;
  background-color: #f8f9fa;
`;

const DebateCard = styled(Card)`
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
`;

const DebateCardBody = styled(Card.Body)`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: -20px;
  margin-top: 10px;
`;

const MetaInfo = styled.div`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Icon = styled.img`
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Category = styled.span`
  background-color: #6c757d;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
`;

const Author = styled.span`
  font-weight: bold;
  position: relative;
  padding-right: 10px;

  &::after {
    content: '|';
    position: absolute;
    right: 0;
    color: #ccc;
    font-weight: normal;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const DateCreated = styled.span`
  color: #333;
  margin-left: 8px;
`;

const Content = styled.div`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 0.5px;
  }
`;

const CommentSection = styled.div`
  margin-top: 40px;
`;

const ChatroomButton = styled(Button)`
  background-color: #343a40;
  border-color: #343a40;
  font-size: 1.1rem;
  padding: 10px 20px;
  border-radius: 30px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #495057;
    border-color: #495057;
    transform: scale(1.05);
  }

  &:active {
    background-color: #212529 !important;
    border-color: #212529 !important;
    transform: scale(0.95);
  }
`;

const StyledDropdownToggle = styled(Dropdown.Toggle).attrs({
  variant: 'transparent',
  id: 'dropdown-basic'
})`
  color: white;
  background-color: transparent;
  border: none; // border 제거
  padding: 0;
  &:after {
    display: none; // 기본 화살표 숨김
  }
  &:hover, &:focus {
    color: white; // Hover, focus 상태에서도 흰색 유지
    background-color: transparent; // 배경색 변경 방지
    border: none; // border 제거
  }

  // PC 버전에서 검은색으로 변경합니다.
  @media (min-width: 768px) {
    color: black; // PC 버전에만 적용되는 검은색

    &:hover, &:focus {
      color: black; // PC에서 hover, focus 상태일 때 검은색 유지
    }
  }
`;

const BackButton = styled(Button)`
  display: none; // 기본적으로 버튼을 숨깁니다.

  @media (min-width: 768px) { // 태블릿과 데스크탑에서만 표시
    display: inline-block;
    margin-bottom: 20px;
    border: none;
    background-color: transparent;
    color: black; // 검은색으로 변경
    font-size: 1.5rem; // 폰트 크기를 크게 조정
  }

  &:hover {
    color: grey; // 호버 시 그레이 색상으로 변경
    background-color: transparent; // 호버 시 배경색 변경 없음
  }

  &:active {
    color: grey !important;
    background-color: transparent !important;
  }
`;

const DebateDetail = () => {
  const [debate, setDebate] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id, commentId } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const currentUser = useSelector((state) => state.user.userInfo);
  const isAuthor = currentUser?.userId === debate?.authorId;
  const isAdmin = currentUser?.isAdmin || false;
  const [documentExists, setDocumentExists] = useState(true);

  useEffect(() => {
    const fetchDebate = async () => {
      const docRef = doc(db, "posts", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setDebate({ id: docSnap.id, ...docSnap.data() });
        setDocumentExists(true);
      } else {
        console.log("No such document!");
        setDocumentExists(false);
      }
      setLoading(false); // 데이터 로딩 완료
    };
    fetchDebate();
  }, [id]);

  useEffect(() => {
  if (commentId) {
    const scrollToComment = () => {
      const element = document.getElementById(commentId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    setTimeout(scrollToComment, 500);
  }
}, [commentId]);

  useEffect(() => {
    if (!loading && !documentExists) {
      alert("삭제된 게시글입니다.");
      navigate('/');
    }
  }, [loading, documentExists, navigate]);


  if (loading) {
    return <LoadingScreen />; // 로딩 중일 때 로딩 컴포넌트를 렌더링
  }
  if (!documentExists) {
    return null; // 게시물이 존재하지 않는 경우 null을 반환하여 렌더링하지 않음
  }

  const cleanHTML = DOMPurify.sanitize(debate.content);

  const handleDeletePost = async () => {

    if (!currentUser) {
      alert("게시글 삭제는 해당 게시글의 작성자만 가능합니다.");
      return; // 함수 종료
    }
    try {
      await deletePost(id, currentUser.userId, isAdmin);
      navigate('/');
    } catch (error) {
      console.error('게시글 삭제 중 오류가 발생했습니다:', error);
      // 오류 처리
    }
  };

  function formatDebateDate(debateCreatedAt) {
    if (!debateCreatedAt) {
      return "Unknown";
    }
  
    const now = new Date();
    const date = new Date(debateCreatedAt.seconds * 1000);
    const diff = now - date;
  
    const minute = 60000;
    const hour = 3600000;
    const day = 86400000;
  
    if (diff < minute) {
      return "방금";
    } else if (diff < hour) {
      const minutes = Math.floor(diff / minute);
      return `${minutes}분 전`;
    } else if (diff < day) {
      const hours = Math.floor(diff / hour);
      return `${hours}시간 전`;
    } else {
      return date.toLocaleString('ko-KR', {
        year: 'numeric', // 연도
        month: 'long',   // 월 (긴 이름)
        day: 'numeric',  // 일
        hour: '2-digit', // 시간 (12시간제)
        minute: '2-digit', // 분
        hour12: true     // 12시간제 사용
      });
    }
  }

  const formattedDate = formatDebateDate(debate.createdAt);


    const handleEnterChatRoom = () => {
      // 로그인 상태 확인
      if (!currentUser) {
        // 로그인하지 않은 경우 알림 표시
        if (window.confirm("채팅방에 입장하려면 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?")) {
          navigate('/');
        }
      } else {
        // 로그인 한 상태에서 채팅방 ID가 있는지 확인
        const chatRoomId = debate.chatRoomId;
        if (chatRoomId) {
          navigate(`/posts/${id}/chatrooms/${chatRoomId}`);
        } else {
          // 채팅방이 존재하지 않는 경우
          alert("채팅방이 존재하지 않습니다.");
        }
      }
    };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    alert('URL이 클립보드에 복사되었습니다.');
  };

  const MoreVertMenu = () => (
    <Dropdown>
  <StyledDropdownToggle variant="transparent" id="dropdown-basic">
    <FontAwesomeIcon icon={faEllipsisV} size="lg" />
  </StyledDropdownToggle>

  <Dropdown.Menu>

    <Dropdown.Item onClick={handleCopyUrl}>
      <FontAwesomeIcon icon={faCopy} /> URL 복사
    </Dropdown.Item>
    {(isAuthor || isAdmin) && (
      <>
        <Dropdown.Item onClick={() => navigate(`/edit/${id}`)}>
          <FontAwesomeIcon icon={faEdit} /> 수정하기
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
          <FontAwesomeIcon icon={faTrashAlt} /> 삭제하기
        </Dropdown.Item> 
      </>
    )}
  </Dropdown.Menu>
</Dropdown>

  );

  return (
    <>
      <MobileHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Icon src={ArrowBack} alt="Back" onClick={() => navigate('/')} />
          <MoreVertMenu />
        </div>
      </MobileHeader>
      <Container>
        <BackButton onClick={() => navigate('/')}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </BackButton>
        <DebateCard>
          <DebateCardBody>
            <Category>{debate.category}</Category>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <Title>{debate.title}</Title>
              <MoreVertMenu />
            </div>
            <MetaInfo>
              
              <Author>
              <Link to={`/profile/${debate.authorId}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                {debate.author}
              </Link>
              </Author>
              <DateCreated>{formattedDate}</DateCreated>
            </MetaInfo>
            <Content dangerouslySetInnerHTML={{ __html: cleanHTML }} />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <ChatroomButton variant="primary" onClick={handleEnterChatRoom}>
                채팅방 입장
              </ChatroomButton>
            </div>
          </DebateCardBody>
        </DebateCard>
        <CommentSection>
          <AddComment postId={id} />
        </CommentSection>
        {(isAuthor || isAdmin) && (
            <ConfirmationModal
              show={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              onConfirm={handleDeletePost}
              title="게시글 삭제 확인"
              body="이 게시글을 삭제하시겠습니까? 삭제 후 복구는 불가능합니다."
            />
        )}
      </Container>
    </>
  );
};

export default DebateDetail;