import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db } from '../firebaseConfig';
import { collection, addDoc, serverTimestamp, query, orderBy, onSnapshot, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { Card, Form, Button, ListGroup } from 'react-bootstrap';
import ReplyForm from '../components/ReplyForm';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../components/ConfirmationModal';
import { Link } from 'react-router-dom';
import { deleteComment } from './FirestoreUtil';


const CommentItem = styled(ListGroup.Item)`
  display: flex;
  justify-content: space-between;
  flex-direction: column; // 수직 쌓기
  align-items: flex-start; // 시작점 정렬
  height: auto;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  position: relative;
`;

const ReplyItem = styled(ListGroup.Item)`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  border-left: 3px solid #ddd;
  padding-left: 1rem;
  font-size: 0.9rem;
`;

const ReplyContent = styled.div`
  display: flex;
  align-items: center; // 내용을 중앙 정렬
  justify-content: space-between; // 내용을 양 끝으로 정렬
  margin-bottom: 10px;
`;


const CommentButton = styled(Button)`
  float: right;
  color: white;
  background-color: #343a40;
  border-color: #343a40;
  border-radius: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #495057;
    border-color: #495057;
    transform: scale(1.05);
  }

  &:active {
    background-color: #212529 !important;
    border-color: #212529 !important;
    transform: scale(0.95);
  }

  &:disabled {
    background-color: #6c757d;
    border-color: #6c757d;
    cursor: not-allowed;
    transform: none;
  }
`;


const DeleteButton = styled.span`
  visibility: hidden; // 기본적으로 보이지 않도록 설정
  color: gray;
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  ${CommentItem}:hover & {
    visibility: visible; // 호버 시 보이도록 설정
  }
`;

// ReplyItem에 대해서도 같은 스타일 적용
const DeleteButtonReply = styled(DeleteButton)`
  ${ReplyItem}:hover & {
    visibility: visible;
  }
`;

const Timestamp = styled.span`
  color: #aaa; // 연한 회색
  font-size: 0.8rem; // 작은 폰트 크기
  margin-bottom: 5px; // 상단 여백
  padding-top: -10px;
`;

const Author = styled(Link)`
  font-weight: bold;
  color: inherit; // Link 기본 색상을 상속받도록 설정
  text-decoration: none; // 기본 링크 밑줄 제거
  margin-bottom: 5px;

  &:hover {
    text-decoration: underline; // 마우스 호버 시 밑줄 표시
  }
`;

function formatDate(timestamp) {
  if (!timestamp) {
    return '';
  }
  
  const now = new Date(); // 현재 시간
  const date = new Date(timestamp.seconds * 1000); // 타임스탬프를 바탕으로 Date 객체 생성
  const diff = now - date; // 차이 계산 (밀리초 단위)

  const minute = 60000; // 밀리초 단위의 1분
  const hour = 3600000; // 밀리초 단위의 1시간
  const day = 86400000; // 밀리초 단위의 1일

  if (diff < minute) {
    return "방금";
  } else if (diff < hour) {
    const minutes = Math.floor(diff / minute);
    return `${minutes}분 전`;
  } else if (diff < day) {
    const hours = Math.floor(diff / hour);
    return `${hours}시간 전`;
  } else {
    return date.toLocaleString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }).replace(/\./g, '').replace(/\s/g, ' ').replace(/:/g, ':');
  }
}

const AddComment = ({ postId }) => {
  const user = useSelector(state => state.user.userInfo);
  const isAdmin = user?.isAdmin || false;
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [replies, setReplies] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentItemToDelete, setCurrentItemToDelete] = useState(null);

  useEffect(() => {
    const commentsRef = collection(db, "posts", postId, "comments");
    const q = query(commentsRef, orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, snapshot => {
      const commentsArray = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setComments(commentsArray);
    });
    return () => unsubscribe();
  }, [postId]);

  useEffect(() => {
    const unsubscribeRepliesList = comments.map((comment) => {
      const repliesRef = collection(db, "posts", postId, "comments", comment.id, "replies");
      const q = query(repliesRef, orderBy("createdAt", "asc"));
      return onSnapshot(q, (snapshot) => {
        const repliesForComment = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReplies((prevReplies) => ({
          ...prevReplies,
          [comment.id]: repliesForComment,
        }));
      });
    });
  
    // 컴포넌트가 언마운트될 때 구독을 해지합니다.
    return () => {
      unsubscribeRepliesList.forEach((unsubscribe) => unsubscribe());
    };
  }, [comments]); // 의존성 배열에 comments 추가  

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (user && comment.trim()) {
      await addDoc(collection(db, "posts", postId, "comments"), {
        text: comment,
        createdAt: serverTimestamp(),
        nickname: user.nickname,
        authorId: user.userId,
      });
      setComment('');
    }
  };

  const addReplyToComment = async (commentId, reply) => {
    try {
      await addDoc(collection(db, "posts", postId, "comments", commentId, "replies"), {
        text: reply.text,
        authorId: reply.authorId,
        nickname: reply.nickname,
        createdAt: serverTimestamp()
      });
      console.log("Reply added to comment");
    } catch (error) {
      console.error("Error adding reply to comment: ", error);
    }
  };

  const handleDeleteReply = async (commentId, replyId) => {
    try {
      const replyRef = doc(db, "posts", postId, "comments", commentId, "replies", replyId);
      await deleteDoc(replyRef);
      console.log("Reply deleted successfully");
    } catch (error) {
      console.error("Error deleting reply: ", error);
    }
  };

  const handleDeleteCommentClick = (commentId) => {
    if (user?.userId) {  // 선택적 체이닝을 사용하여 user가 null이 아니어야 접근
      setShowModal(true);
      setCurrentItemToDelete({ type: 'comment', id: commentId });
    } else {
      alert("로그인이 필요합니다.");
    }
  };
  
  const handleDeleteReplyClick = (commentId, replyId) => {
    if (user?.userId) {  // 선택적 체이닝을 사용하여 user가 null이 아니어야 접근
      setShowModal(true);
      setCurrentItemToDelete({ type: 'reply', commentId, id: replyId });
    } else {
      alert("로그인이 필요합니다.");
    }
  };
  
  const confirmDelete = async () => {
    if (currentItemToDelete) {
      if (currentItemToDelete.type === 'comment') {
        await deleteComment(postId, currentItemToDelete.id, user.userId, isAdmin);
      } else if (currentItemToDelete.type === 'reply') {
        await handleDeleteReply(currentItemToDelete.commentId, currentItemToDelete.id);
      }
      setShowModal(false);
      setCurrentItemToDelete(null);
    }
  };
  
  const closeModal = () => {
    setShowModal(false);
    setCurrentItemToDelete(null);
  };
  

  return (
    <>
      <Card className="mt-4">
        <Card.Header>댓글 작성</Card.Header>
        <Card.Body>
          <Form onSubmit={handleCommentSubmit}>
            <Form.Group controlId="commentTextarea">
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={e => setComment(e.target.value)}
                placeholder={user ? "댓글을 남겨주세요..." : "댓글을 작성하려면 로그인 해주세요."}
                disabled={!user}
              />
            </Form.Group>
            <CommentButton variant="primary" type="submit" className="mt-2" disabled={!user}>
              등록
            </CommentButton>
          </Form>
        </Card.Body>
      </Card>
      <ListGroup className="mt-3">
        {comments.map((comment) => (
          <React.Fragment key={comment.id}>
            <CommentItem id={comment.id} action onClick={() => setActiveCommentId(comment.id)}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Author to={`/profile/${comment.authorId}`}>
                {comment.nickname}
              </Author>
                {(user?.userId === comment.authorId || isAdmin) && (
                  <DeleteButton onClick={(e) => { e.stopPropagation(); handleDeleteCommentClick(comment.id); }}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </DeleteButton>
                )}
              </div>
              <p>{comment.text}</p>
              <Timestamp style={{ marginTop: '-14px' }}>{formatDate(comment.createdAt)}</Timestamp>
              {activeCommentId === comment.id && (
                <div style={{ width: '100%' }}>
                <ReplyForm postId={postId} commentId={comment.id} onReplySubmit={addReplyToComment} isUserLoggedIn={!!user} />
                </div>
              )}
            </CommentItem>
            {replies[comment.id]?.map((reply) => (
              <ReplyItem key={reply.id}>
                <ReplyContent>
                  <div>
                    <Author to={`/profile/${reply.authorId}`}>
                      {reply.nickname}
                    </Author>: {reply.text}
                  </div>
                  {(user?.userId === reply.authorId || isAdmin) && (
                  <DeleteButtonReply onClick={(e) => { e.stopPropagation(); handleDeleteReplyClick(comment.id, reply.id); }}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </DeleteButtonReply>
                )}
                </ReplyContent>
                <Timestamp>{formatDate(reply.createdAt)}</Timestamp>
              </ReplyItem>
            ))}
          </React.Fragment>
        ))}
      </ListGroup>
      <ConfirmationModal
      show={showModal}
      onClose={closeModal}
      onConfirm={confirmDelete}
      title="삭제 확인"
      body="댓글을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다."
    />
    </>
  );
};

export default AddComment;
