import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import GroupIcon from '../icons/Group.svg';
import ExitIcon from '../icons/Exit.svg';
import CloseIcon from '../icons/Close.svg'; // 닫기 아이콘 추가

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

const SelectionBox = styled.div`
  position: relative; // 상대적 위치 지정
  background-color: white;
  padding: 40px 20px 20px 20px; // 위쪽 패딩을 더 크게 조정하여 CloseButton 공간 확보
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: auto; // 너비 자동 조정
  min-width: 300px; // 최소 너비 설정
  @media (min-width: 768px) {
    min-width: 400px; // PC 버전에서 패널 크기 조정
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: 10px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute; // 절대적 위치 지정
  top: 10px;
  right: 10px;
  width: 24px; // 아이콘 크기
`;  

const SettingPanel = ({ isHost, userRole, toggleUserRole, leaveChatRoom, onClose, onShowSpeakerLimitModal, onDeleteChatRoom }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleConfirmChange = () => {
    toggleUserRole();
    setShowModal(false);
  };

  // 현재 사용자의 역할에 따라 변경할 역할의 메시지를 결정
  const changeRoleMessage = userRole === 'speaker' ? "관전자로 역할을 변경하시겠습니까?" : "발언자로 역할을 변경하시겠습니까?";

  if (isHost) {
    // 호스트용 옵션 표시
    return (
      <Overlay>
        <SelectionBox>
          <CloseButton src={CloseIcon} alt="Close" onClick={onClose} />
          <StyledButton variant="primary" onClick={onShowSpeakerLimitModal}>
            <Icon src={GroupIcon} alt="Role Change" />
            발언자 인원 변경
          </StyledButton>
          <StyledButton variant="danger" onClick={onDeleteChatRoom}>
            <Icon src={ExitIcon} alt="Leave Chatroom" />
            방 삭제하기
          </StyledButton>
        </SelectionBox>
      </Overlay>
    );
  } else {
    // 일반 사용자용 옵션 표시
    return (
      <Overlay>
        <SelectionBox>
          <CloseButton src={CloseIcon} alt="Close" onClick={onClose} />
          <StyledButton variant="primary" onClick={handleShowModal}>
            <Icon src={GroupIcon} alt="Role Change" />
            역할 변경
          </StyledButton>
          <StyledButton variant="danger" onClick={leaveChatRoom}>
            <Icon src={ExitIcon} alt="Leave Chatroom" />
            채팅방 나가기
          </StyledButton>
        </SelectionBox>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>역할 변경</Modal.Title>
          </Modal.Header>
          <Modal.Body>{changeRoleMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              아니오
            </Button>
            <Button variant="primary" onClick={handleConfirmChange}>
              예
            </Button>
          </Modal.Footer>
        </Modal>
      </Overlay>
    );
  }
};

export default SettingPanel;