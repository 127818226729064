import { db, storage } from '../firebaseConfig'; // 올바른 경로로 수정하세요.
import { ref, uploadBytes, getDownloadURL, deleteObject, listAll } from 'firebase/storage';
import { collection, addDoc, updateDoc, doc, deleteDoc, getDocs, getDoc, writeBatch, setDoc } from 'firebase/firestore';
import { createChatRoom } from '../pages/Chat/CreateChatRoom';

// Base64 문자열을 Blob으로 변환
const base64ToBlob = (base64) => {
  const byteString = atob(base64.split(',')[1]);
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

// 게시글 내 base64 이미지를 업로드하고 URL 반환
const uploadBase64Image = async (base64, postId, userId) => {
  const imageBlob = base64ToBlob(base64);
  const imageRef = ref(storage, `posts/${postId}/${Date.now()}`);
  const metadata = {
    customMetadata: {
      authorId: userId  // 사용자 ID를 메타데이터로 추가
    }
  };
  await uploadBytes(imageRef, imageBlob, metadata);
  return getDownloadURL(imageRef);
};

// 게시글 내용에서 base64 이미지 URL로 교체
const replaceBase64WithUrl = async (content, postId, userId) => {
  const matches = content.match(/src="data:image[^"]+"/g) || [];
  const uploadPromises = matches.map(async (imgTag) => {
    const base64 = imgTag.slice(5, -1); // 'src="' 부분 제거
    const url = await uploadBase64Image(base64, postId, userId);
    content = content.replace(base64, url);
  });

  await Promise.all(uploadPromises);
  return content;
};



// Firestore에 게시물 업로드하고 채팅방 생성 후 게시물 문서에 채팅방 ID 저장
export const uploadPostAndCreateChatRoom = async (title, originalContent, category, nickname, userId, maxSpeakers) => {
  try {

    // 사전에 문서 ID 생성
    const postRef = doc(collection(db, "posts"));
    const postId = postRef.id;

    // 이미지 URL로 base64 교체
    const content = await replaceBase64WithUrl(originalContent, postId, userId);

    // 일단 모든 컨텐츠를 업로드하고, 이미지 URL로만 교체합니다.
    await setDoc(postRef, {
      title,
      content,
      category,
      author: nickname,
      authorId: userId,
      createdAt: new Date(),
    });

    // 채팅방 생성
    const chatRoomId = await createChatRoom(postId, userId, maxSpeakers);

    // 게시물 문서에 채팅방 ID 저장
    await updateDoc(doc(db, "posts", postId), {
      chatRoomId: chatRoomId,
    });

    console.log("Document written with ID: ", postId, " and chat room ID: ", chatRoomId);
    return { postId, chatRoomId };
  } catch (e) {
    console.error("Error: ", e);
    return null;
  }
};


// 채팅방과 그 안의 메시지들을 삭제하는 함수
export const deleteChatRooms = async (postId, userId, isAdmin) => {
  try {
    const chatRoomsRef = collection(db, "posts", postId, "chatrooms");
    const chatRoomsSnapshot = await getDocs(chatRoomsRef);

    for (const chatRoomDoc of chatRoomsSnapshot.docs) {
      // 채팅방 문서에 host가 있어야 합니다.
      const chatRoomData = chatRoomDoc.data();
      // 현재 사용자가 호스트와 동일한지 확인
      if (chatRoomData.host !== userId && !isAdmin) {
        console.log("You are not the host of this chat room.");
        continue; // 호스트가 아닌 경우 다음 채팅방으로 넘어갑니다.
      }

      const messagesRef = collection(db, "posts", postId, "chatrooms", chatRoomDoc.id, "messages");
      const messagesSnapshot = await getDocs(messagesRef);

      for (const messageDoc of messagesSnapshot.docs) {
        await deleteDoc(doc(db, "posts", postId, "chatrooms", chatRoomDoc.id, "messages", messageDoc.id));
      }

      await deleteDoc(doc(db, "posts", postId, "chatrooms", chatRoomDoc.id));
    }

    console.log("All chat rooms and messages deleted successfully.");
  } catch (e) {
    console.error("Error deleting chat rooms and messages: ", e);
  }
};

// 개별 댓글과 해당 댓글의 모든 답글 삭제
export const deleteComment = async (postId, commentId, userId, isAdmin) => {
  try {
    const commentRef = doc(db, "posts", postId, "comments", commentId);
    const commentSnap = await getDoc(commentRef);
    if (!commentSnap.exists()) {
      console.log("Comment not found!");
      return;
    }

    const comment = commentSnap.data();
    if (comment.authorId !== userId && !isAdmin) {
      console.log("You are not the author of this comment.");
      return;
    }

    // Delete all replies associated with this comment
    const repliesRef = collection(commentRef, "replies");
    const repliesSnap = await getDocs(repliesRef);
    const batch = writeBatch(db);
    repliesSnap.docs.forEach(replyDoc => {
      batch.delete(replyDoc.ref);
    });

    // Delete the comment itself
    batch.delete(commentRef);
    await batch.commit();
    console.log("Comment and all replies deleted successfully.");
  } catch (e) {
    console.error("Error deleting comment and replies: ", e);
  }
};

// 게시글의 모든 댓글과 각 댓글의 모든 답글 삭제
async function deleteAllComments(postId) {
  const commentsRef = collection(db, "posts", postId, "comments");
  const commentsSnapshot = await getDocs(commentsRef);

  const batch = writeBatch(db);
  for (const commentDoc of commentsSnapshot.docs) {
    const repliesRef = collection(commentDoc.ref, "replies");
    const repliesSnapshot = await getDocs(repliesRef);
    repliesSnapshot.docs.forEach(replyDoc => {
      batch.delete(replyDoc.ref);
    });
    batch.delete(commentDoc.ref);
  }

  await batch.commit();
  console.log("All comments and their replies deleted successfully.");
}

// 게시글 수정 함수
// 게시글 수정 함수
export async function updatePost(postId, newContent, userId) {
  const postRef = doc(db, "posts", postId);
  const postSnap = await getDoc(postRef);

  if (!postSnap.exists()) {
    console.error("Post not found!");
    return;
  }

  const postData = postSnap.data();
  const oldContent = postData.content;

  // 새로운 이미지를 Firebase Storage에 업로드하고 URL로 교체
  const contentWithNewUrls = await replaceBase64WithUrl(newContent, postId, userId);

  // 기존 이미지 URL 추출 (토큰 제거)
  const oldImagesUrls = oldContent.match(/https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/proxyargument\.appspot\.com\/o\/posts%2F[\w-]+%2F\d+/g) || [];

  // 새 이미지 URL 추출 (토큰 제거)
  const newImagesUrls = contentWithNewUrls.match(/https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/proxyargument\.appspot\.com\/o\/posts%2F[\w-]+%2F\d+/g) || [];

  // 이미지 URL로 변경된 새 콘텐츠와 기존 콘텐츠 비교하여 삭제할 이미지 확인
  const imagesToDelete = oldImagesUrls.filter(url => !newImagesUrls.includes(url));

  // 삭제할 이미지를 Firebase Storage에서 제거
  await Promise.all(imagesToDelete.map(url => deleteImage(url)));

  // Firestore 문서 업데이트
  await updateDoc(postRef, { content: contentWithNewUrls });
  console.log("Post updated successfully with new images and deleted old images.");
}

// Firebase Storage에서 이미지 삭제
async function deleteImage(imageUrl) {
  console.log("Image URL:", imageUrl);
  const imageRef = ref(storage, imageUrl);
  await deleteObject(imageRef);
  console.log("Deleted image from storage:", imageUrl);
}

// 게시글과 연관된 모든 이미지들을 Storage에서 삭제하는 함수
async function deleteAllPostImages(postId) {
  const imagesRef = ref(storage, `posts/${postId}`);
  try {
    const listResult = await listAll(imagesRef);
    const deletePromises = listResult.items.map(itemRef => deleteObject(itemRef));
    await Promise.all(deletePromises);
    console.log("All images deleted successfully.");
  } catch (error) {
    console.error("Error deleting images:", error);
  }
}

// 게시글 삭제 함수
export const deletePost = async (postId, userId, isAdmin) => {
  try {
    const postRef = doc(db, "posts", postId);
    const postSnap = await getDoc(postRef);

    if (!postSnap.exists()) {
      return; // 포스트가 없으면 바로 종료
    }

    const post = postSnap.data();

    if (post.authorId !== userId && !isAdmin) {
      return; // 권한이 없으면 바로 종료
    }

    // 이미지와 댓글은 즉시 삭제
    await deleteAllPostImages(postId);
    await deleteAllComments(postId);

    // 포스트 문서 삭제
    await deleteDoc(postRef);

    // 채팅룸 삭제는 비동기적으로 실행
    deleteChatRooms(postId, userId, isAdmin);

  } catch (e) {
    console.error("Error deleting post: ", e);
  }
};
