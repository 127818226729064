import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import notificationsReducer  from '../features/notifications/notificationsSlice';

const store = configureStore({
  reducer: {
    user: userReducer, // 여기서 'user'는 상태 구조에서 사용할 키입니다.
    notifications: notificationsReducer,
  },
});

export default store;