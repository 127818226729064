import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const SpeakerLimitModal = ({ show, onHide, onSubmit, currentLimit, currentSpeakerCount }) => {
  const [newLimit, setNewLimit] = useState(currentLimit);

  useEffect(() => {
    setNewLimit(currentLimit);
  }, [currentLimit, show]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(newLimit);
    onHide();
  };

  // 현재 발언자 수 이상부터 6까지의 옵션 생성
  const limitOptions = Array.from({ length: 6 - currentSpeakerCount + 1 }, (_, i) => currentSpeakerCount + i);

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>발언자 인원 제한 변경</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>새로운 발언자 인원 제한</Form.Label>
            <Form.Select
              value={newLimit}
              onChange={(e) => setNewLimit(parseInt(e.target.value, 10))}
              aria-label="발언자 인원 제한 선택"
            >
              {limitOptions.map((limit) => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>취소</Button>
          <Button variant="primary" type="submit">변경</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SpeakerLimitModal;
