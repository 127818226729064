import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import DebateDetail from './pages/DebateDetail'
import CreatePost from './pages/CreatePost';
import EditPost from './pages/EditPost';
import LoadingScreen from './components/LoadingScreen';
import NotFoundPage from './pages/NotFoundPage';
import ProfilePage from './pages/ProfilePage';
import { useDispatch } from 'react-redux';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from './firebaseConfig';
import { setUser, clearUser } from './features/userSlice'; 
import ChatRoomUI from './pages/Chat/ChatRoomUI';
import GlobalNotificationToast from './features/notifications/GlobalNotificationToast ';
import { useNotificationListener } from './features/notifications/useNotificationListener';


if (process.env.NODE_ENV === "production") {
  console = window.console || {};
  console.log = function no_console() {};
  console.warn = function no_console() {};
  console.error = function () {};
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const auth = getAuth();

  useNotificationListener();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Firestore에서 사용자 정보 조회
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          // Redux 스토어에 사용자 정보 저장
          // ID 토큰 결과를 가져와서 Custom Claims 확인
          const idTokenResult = await user.getIdTokenResult();
          dispatch(setUser({
            userId: user.uid,
            email: user.email,
            nickname: userDocSnap.data().nickname,
            isAdmin: idTokenResult.claims.isAdmin || false
          }));
        }
      } else {
        // 사용자가 로그아웃한 경우
        dispatch(clearUser());
      }
      setIsLoading(false);
    });

    return () => unsubscribe(); // 구독 해제
  }, [auth, dispatch]);
  
  if (isLoading) {
    return <LoadingScreen />; // 로딩 중일 때 로딩 컴포넌트를 렌더링
  }

  return (
    <Router>
      <GlobalNotificationToast />
      <div>
        <Routes>          
          <Route exact path="/" element={<Home />} />
          <Route path="/posts/:id" element={<DebateDetail />} />
          <Route path="/posts/:id/comments/:commentId" element={<DebateDetail />} />
          <Route path="/create-post" element={<CreatePost />} />
          <Route path="/posts/:postId/chatrooms/:chatRoomId" element={<ChatRoomUI />} />
          <Route path="/edit/:postId" element={<EditPost />} />
          <Route path="/profile/:userId" element={<ProfilePage />} />
          <Route path="*" element={<NotFoundPage />} />
          {/* 다른 라우트 추가 */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;