import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const NotFoundPage = () => {
  return (
    <Container className="d-flex align-items-center justify-content-center min-vh-100">
      <Row className="text-center">
        <Col>
          <FontAwesomeIcon icon={faExclamationTriangle} size="6x" className="text-warning mb-4" />
          <h1 className="display-1 mb-4">404</h1>
          <p className="lead mb-4">죄송합니다, 찾으시는 페이지가 존재하지 않습니다.</p>
          <p className="mb-5">
            홈페이지로 돌아가려면 <Link to="/">홈페이지</Link>를 누르십시오.
          </p>
          <Button as={Link} to="/" variant="primary" size="lg">
            홈페이지로 이동
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;