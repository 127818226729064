// MobileHeader.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const MobileHeaderContainer = styled.nav`
  position: fixed; // 스크롤해도 항상 화면 상단에 고정
  top: 0; // 상단에서부터 0의 위치에 있음
  left: 0; // 왼쪽에서부터 0의 위치에 있음
  height:43px;
  width: 100%; // 전체 너비를 차지하도록 설정
  background-color: #333; // 배경색 설정
  color: #fff; // 텍스트 색상을 하얗게 설정
  padding: 0.5rem 1rem; // 상하 0.5rem, 좌우 1rem의 패딩 적용
  z-index: 1000; // 다른 요소들 위에 오도록 z-index 설정
`;

const MobileHeader = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isMobile) return null;

  return <MobileHeaderContainer>{children}</MobileHeaderContainer>;
};

export default MobileHeader;
