import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, Pagination, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { updateNickname } from '../services/FirebaseAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components'; 
import { db } from '../firebaseConfig';
import { doc, getDoc, orderBy, collection, query, where, getDocs  } from 'firebase/firestore';
import MobileHeader from '../components/MobileHeader';
import ArrowBack from '../icons/ArrowBack.svg';
import { useDispatch, useSelector } from 'react-redux';
import { isToday } from 'date-fns';
import { useParams } from 'react-router-dom';

const ProfileContainer = styled(Container)`
  margin-top: 5rem;
  margin-bottom: 5rem;
`;

const ProfileRow = styled(Row)`
  margin-bottom: 5rem;
`;

const ProfileCol = styled(Col)`
  padding: 0 2rem;
`;

const NicknameCard = styled(Card)`
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: #f8f9fa;
  margin-left: auto;
  margin-right: 0;
  max-width: 80%;
  position: relative;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #f1f3f5 0%, #e9ecef 99%, #dee2e6 100%);
    opacity: 0.8;
    z-index: -1;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  .edit-icon,
  .save-icon {
    color: #6c757d;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #343a40;
    }
  }

  .nickname {
    color: #343a40;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    max-width: 80%; /* 중간 크기의 모바일 화면 */
  }

  @media (max-width: 576px) {
    max-width: 70%; /* 가장 작은 모바일 화면에서는 카드 너비를 70%로 줄입니다 */
  }
`;

const EditIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: #6c757d;
  margin-left: 8px;
`;

const PostsTitle = styled.h2`
  margin-bottom: 1.5rem;
  color: #333;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: ${props => props.marginTop || '0'};

  border-top: 1px solid #ddd; // 상단에 경계선 추가
  padding-top: 30px; // 위쪽 여백 추가

  &:first-child {
    border-top: none; // 첫 번째 PostsTitle에는 경계선이 없도록 설정
    padding-top: 0; // 첫 번째 PostsTitle에는 위쪽 여백이 없도록 설정
  }
`;

const PostsCol = styled(Col)`
  border-left: 1px solid #ccc;
  padding-left: 2rem;

  @media (min-width: 768px) {
    border-left: 1px solid #ccc;
    padding-left: 2rem;
  }

  // 작은 화면 크기에서 적용될 스타일
  @media (max-width: 767.98px) {
    border-left: none;
    padding-left: 0;
  }
`;

const Icon = styled.img`
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const BackButton = styled(Button)`
  display: none; // 기본적으로 버튼을 숨깁니다.

  @media (min-width: 768px) { // 태블릿과 데스크탑에서만 표시
    display: inline-block;
    margin-bottom: 20px;
    border: none;
    background-color: transparent;
    color: black; // 검은색으로 변경
    font-size: 1.5rem; // 폰트 크기를 크게 조정
  }

  &:hover {
    color: grey; // 호버 시 그레이 색상으로 변경
    background-color: transparent; // 호버 시 배경색 변경 없음
  }

  &:active {
    color: grey !important;
    background-color: transparent !important;
  }
`;

const SaveIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: #6c757d;
  margin-left: 20px;
`;

const PostRow = styled(Row)`
  font-size: 1rem;
  border-bottom: 1px solid #dee2e6;
  padding: 15px 0;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-left: 6px;
  
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
  }
`;

const CategoryCol = styled.div`
  flex: 0.8;
  font-weight: bold;
  color: grey;

  // 모바일 화면에서의 스타일
  @media (max-width: 768px) {
    flex: none; // flex 값을 초기화
    display: block; // 항상 보이도록 설정
    font-size: 0.7rem; // 작은 폰트 크기
  }
`;

const TitleCol = styled.div`
  flex: 5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 768px) {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const DateCol = styled.div`
  flex: 1;
  text-align: right;
  color: #6c757d;
  font-style: italic;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const StyledPagination = styled(Pagination)`
  margin-top: 20px;
  justify-content: center;

  .page-link {
    color: #6c757d;
    background-color: #fff;
    border-color: #6c757d;

    &:hover {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
    }
  }

  .page-item.active .page-link {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
`;

  // 게시물 작성일을 형식화하는 함수
  const formatDate = (timestamp) => {
    const date = timestamp.toDate(); // Firestore timestamp를 JavaScript Date 객체로 변환
    if (isToday(date)) {
      return date.toLocaleTimeString([], { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }); // 24시간 형식으로 당일 게시글 시간과 분 표시
    } else {
      // 월과 일 사이의 공백을 제거하고, 문자열 끝의 점을 제거
      return date.toLocaleDateString('ko-KR', { month: '2-digit', day: '2-digit' })
                 .replace(/\.\s/g, ".") // 모든 월과 일 사이의 점 뒤 공백 제거
                 .replace(/\.$/, "");   // 문자열 끝의 점 제거
    }
  };  



const PostCard = ({ post }) => {
  const navigate = useNavigate();
  

  const handlePostClick = () => {
    navigate(`/posts/${post.id}`);
  };

  return (
    <PostRow onClick={handlePostClick}>
      <CategoryCol>{post.category}</CategoryCol>
      <TitleCol>{post.title}</TitleCol>
      <DateCol>{post.dateFormatted}</DateCol>
    </PostRow>
  );
};


const ProfilePage = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [nickname, setNickname] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const [participatedPosts, setParticipatedPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentParticipatedPage, setCurrentParticipatedPage] = useState(1);
  const postsPerPage = 8;
  const currentUser = useSelector((state) => state.user.userInfo);

  const isCurrentUser = currentUser && currentUser.userId === userId;
  // Pagination for authored posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination for participated posts
  const indexOfLastParticipatedPost = currentParticipatedPage * postsPerPage;
  const indexOfFirstParticipatedPost = indexOfLastParticipatedPost - postsPerPage;
  const currentParticipatedPosts = participatedPosts.slice(indexOfFirstParticipatedPost, indexOfLastParticipatedPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const paginateParticipated = (pageNumber) => setCurrentParticipatedPage(pageNumber);
  useEffect(() => {

    const fetchProfileData = async () => {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setNickname(userData.nickname);
      }
    };

    fetchProfileData();

    const fetchUserPosts = async () => {
      const userQuery = query(collection(db, "posts"), where("authorId", "==", userId), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(userQuery);
      const postsArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        dateFormatted: formatDate(doc.data().createdAt)
      }));
      setPosts(postsArray);
    };
    fetchUserPosts();
  
    const fetchParticipatedPosts = async () => {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const postsIds = userData.participatedPosts || [];
        const postsPromises = postsIds.map(id => getDoc(doc(db, "posts", id)));
        const postsSnapshots = await Promise.all(postsPromises);
        const validPosts = postsSnapshots
          .filter(docSnapshot => docSnapshot.exists())
          .map(docSnapshot => ({
            id: docSnapshot.id,
            ...docSnapshot.data(),
            dateFormatted: formatDate(docSnapshot.data().createdAt)
          }))
          .sort((a, b) => b.createdAt - a.createdAt);
        setParticipatedPosts(validPosts);
      }
    };
    fetchParticipatedPosts();
  }, [userId]);


  

  const handleNicknameChange = (e) => {
    const newNickname = e.target.value;
    if (newNickname.length <= 8) {
      setNickname(newNickname);
      setErrorMessage('');
    } else {
      setErrorMessage('닉네임은 최대 8자까지 가능합니다.');
    }
  };
  
  const handleEditNickname = () => {
    setEditMode(true);
  };

  const handleSaveNickname = async () => {
    if (nickname.length < 2 || nickname.length > 8) {
      setErrorMessage('닉네임은 최소 2자에서 최대 8자까지 가능합니다.');
      return;
    }
    setEditMode(false);
    await updateNickname(userId, nickname, dispatch);
  };

  return (
    <>
    <MobileHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Icon src={ArrowBack} alt="Back" onClick={() => navigate('/')} />
        </div>
      </MobileHeader>
    <ProfileContainer>
      <BackButton onClick={() => navigate('/')}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </BackButton>
      <ProfileRow>
        <ProfileCol md={4}>
          <NicknameCard>
            <Card.Body>
              {editMode ? (
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    value={nickname}
                    onChange={handleNicknameChange}
                    style={{ width: '100%' }}
                    autoComplete="off"
                  />
                  <SaveIcon icon={faSave} onClick={handleSaveNickname} />
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <div style={{ flexGrow: 1 }}>{nickname}</div>
                  {isCurrentUser && (
                      <EditIcon icon={faEdit} onClick={handleEditNickname} />
                  )}
                </div>
              )}
              {errorMessage && <Alert variant="danger" className="mt-2">{errorMessage}</Alert>}
            </Card.Body>
          </NicknameCard>
        </ProfileCol>
        <PostsCol md={8}>
            <PostsTitle>작성한 게시물</PostsTitle>
            {currentPosts.length > 0 ? (
              currentPosts.map((post) => (
                <PostCard key={post.id} post={post} />
              ))
            ) : (
              <div style={{ textAlign: 'center', width: '100%' }}>작성한 게시물이 없습니다.</div>
            )}
            {posts.length > postsPerPage && (
              <StyledPagination className="justify-content-center">
                {Array.from({ length: Math.ceil(posts.length / postsPerPage) }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </StyledPagination>
            )}
            <PostsTitle marginTop="30px">참여한 게시물</PostsTitle>
            {currentParticipatedPosts.length > 0 ? (
              currentParticipatedPosts.map((post) => (
                <PostCard key={post.id} post={post} />
              ))
            ) : (
              <div style={{ textAlign: 'center', width: '100%' }}>참여한 게시물이 없습니다.</div>
            )}
            {participatedPosts.length > postsPerPage && (
              <StyledPagination className="justify-content-center">
                {Array.from({ length: Math.ceil(participatedPosts.length / postsPerPage) }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentParticipatedPage}
                    onClick={() => paginateParticipated(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </StyledPagination>
            )}
          </PostsCol>
        </ProfileRow>
      </ProfileContainer>
    </>
  );
};

export default ProfilePage;