// ChatInput.js
import React, { useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import styled from 'styled-components';


const InputFormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 10px; // 패딩을 추가하여 입력 폼이 좀 더 돋보이게 합니다.
  background-color: #f8f9fa; // 배경색을 설정하여 시각적으로 구분합니다.
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); // 상단에 그림자 효과를 줍니다.
`;


const TextareaWrapper = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;

  .form-control {
    resize: none;
    flex-grow: 1;
  }
`;

const SendButton = styled(Button)`
  flex-shrink: 0;
  margin-left: 10px;
  background-color: #6c757d; // 그레이 색상
  color: white; // 텍스트는 흰색으로
  border: none;

  &:hover {
    background-color: #5a6268; // 호버 시 더 어두운 그레이
    border: none;
  }
  &:active {
    background-color: #545b62 !important; // 클릭 시 더욱 어두운 그레이
    border: none;
  }
  &:disabled {
    background-color: #6c757d !important; // 비활성화 상태에서도 같은 배경 색상 유지
    color: white !important; // 비활성화 상태에서도 텍스트는 흰색으로 유지
    border: none;
    opacity: 0.5; // 비활성화 상태에서 약간 흐릿하게 표시
  }
`;

const ChatInput = ({ newMessage, setNewMessage, handleSendMessage, isSpeaker, handleKeyDown }) => {
  const textareaRef = useRef(null);  // textarea에 대한 참조 생성

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isSpeaker) return;
    handleSendMessage(e);  // ChatRoomUI의 handleSendMessage 함수를 호출
    setNewMessage('');  // 메시지 초기화
    textareaRef.current.focus();  // 포커스를 유지
  };

  return (
    <InputFormContainer>
      <Form onSubmit={handleSubmit}>  
        <TextareaWrapper>
          <Form.Control
            as="textarea"
            ref={textareaRef}
            rows={3}
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={isSpeaker ? "메시지를 입력하세요.." : "채팅을 입력하려면 발언자로 참여해야 합니다."}
            disabled={!isSpeaker}
          />
          <SendButton variant="primary" type="submit" disabled={!isSpeaker}>
            전송
          </SendButton>
        </TextareaWrapper>
      </Form>
    </InputFormContainer>
  );
};

export default ChatInput;
