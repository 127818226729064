import { createSlice } from '@reduxjs/toolkit';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {
    // 알림 추가
    addNotification: (state, action) => {
      state.items.push(action.payload);
    },
    // 알림 삭제
    removeNotification: (state, action) => {
      state.items = state.items.filter(notification => notification.id !== action.payload);
    },
    // 모든 알림 삭제
    clearNotifications: (state) => {
      state.items = [];
    }
  },
});

export const { addNotification, removeNotification, clearNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
