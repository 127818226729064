// src/pages/Chat/ChatRoomUI.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { addMessageToChatRoom, addSpeakerToChatRoom, addViewerToChatRoom, subscribeToChatRoomMessages, removeSpeakerFromChatRoom, removeViewerFromChatRoom, setSpeakerLimit, addNotificationToChatRoom, addPostIdToUser, removePostIdFromUser, addLiveSpeaker, removeLiveSpeaker } from './ChatService';
import { deleteChatRooms } from '../../services/FirestoreUtil';
import { serverTimestamp } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { Container, Form, Button } from 'react-bootstrap';
import { doc, getDoc, onSnapshot, arrayRemove, arrayUnion, runTransaction } from 'firebase/firestore';
import { db  } from '../../firebaseConfig';
import UserRoleSelection from './UserRoleSelection';
import SettingPanel from '../../components/SettingPanel';
import ArrowBackIcon from '../../icons/ArrowBack.svg';
import settingIconImg from '../../icons/Setting.svg';
import ConfirmationModal from '../../components/ConfirmationModal';
import SpeakerLimitModal from '../../components/SpeakerLimitModal';
import GroupIcon from '../../icons/Group.svg'
import ViewerIcon from '../../icons/Viewer.svg'
import LoadingScreen from '../../components/LoadingScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { deleteChatRoomNotifications } from '../../features/notifications/deleteNotification';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ChatInput from '../../components/ChatInput';

const pulse = keyframes`
  0%, 100% {
    transform: scale(0.75);
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.2), 0 0 15px rgba(0, 255, 0, 0.1), 0 0 30px rgba(0, 255, 0, 0.05);
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.7), 0 0 20px rgba(0, 255, 0, 0.5), 0 0 40px rgba(0, 255, 0, 0.3); // 더 강한 빛나는 효과
  }
`;

const Dot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
`;

const OnlineDot = styled(Dot)`
  background-color: green;
  animation: ${pulse} 1.5s ease-in-out infinite;
  animation-delay: -${props => props.index * 0.075}s; // 각 점의 애니메이션 시작 지연
`;

const OfflineDot = styled(Dot)`
  background-color: transparent;
`;


const ChatRoomHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem;;
  justify-content: space-between;
  align-items: center;
  height:43px;
  background-color: #333;
  color: #fff;
`;

const ChatContainer = styled(Container)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; // 추가
  position: relative;
  width: 100%;
  max-width: 600px; // 최대 너비 설정
  padding: 0;
`;

const InputFormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 10px; // 패딩을 추가하여 입력 폼이 좀 더 돋보이게 합니다.
  background-color: #f8f9fa; // 배경색을 설정하여 시각적으로 구분합니다.
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); // 상단에 그림자 효과를 줍니다.
`;

const TextareaWrapper = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;

  .form-control {
    resize: none;
    flex-grow: 1;
  }
`;

const StyledSettingIcon = styled.img`
  cursor: pointer;
  // 필요한 경우 추가 스타일 정의
`;

const ArrowBackButton = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

const SpeakerViewerInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-grow: 1;
`;

const InfoIcon = styled.img`
  margin-right: 5px;
`;

const SendButton = styled(Button)`
  flex-shrink: 0;
  margin-left: 10px;
  background-color: #6c757d; // 그레이 색상
  color: white; // 텍스트는 흰색으로
  border-color: #6c757d; // 테두리도 같은 색

  &:hover {
    background-color: #5a6268; // 호버 시 더 어두운 그레이
    border-color: #5a6268;
  }
  &:active {
    background-color: #545b62 !important; // 클릭 시 더욱 어두운 그레이
    border-color: #545b62 !important;
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding-right: 10px;
  padding-left: 20px;
  padding-top: 5px;
  height: 100%;
  background-color: #f8f9fa;
  border-radius: 10px;
  margin-bottom: 0px;
  max-height: 100%;
  width: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; // 기본 상태에서는 투명하게 설정
    border-radius: 4px;
  }

  ${props => props.isScrolling && `
    &::-webkit-scrollbar-thumb {
      background-color: #888; // 스크롤 중일 때만 스크롤바 색상 표시
    }
  `}

  &::-webkit-scrollbar-thumb:hover {
    background: #555; // 스크롤바 핸들 호버 시 색상 변경
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isCurrentUser }) => (isCurrentUser ? 'row-reverse' : 'row')};
  align-items: flex-end;
  margin-right: ${({ isCurrentUser }) => (isCurrentUser ? '-10px' : 'auto')};
  margin-left: ${({ isCurrentUser }) => (isCurrentUser ? 'auto' : '-10px')};
  margin-bottom: 5px;
`;

const Nickname = styled.div`
  font-size: 0.8rem;
  color: #6c757d;
  margin-bottom: 4px;
`;

const Message = styled.div`
  background-color: ${({ isCurrentUser }) => (isCurrentUser ? '#007bff' : '#e9ecef')};
  color: ${({ isCurrentUser }) => (isCurrentUser ? 'white' : 'black')};
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
`;

const Timestamp = styled.span`
  font-size: 0.8rem;
  color: #6c757d;
  margin-left: ${({ isCurrentUser }) => (isCurrentUser ? '0' : '8px')};
  margin-right: ${({ isCurrentUser }) => (isCurrentUser ? '8px' : '0')};
  align-self: flex-end;
`;

const NotificationMessage = styled.div`
  padding: 5px;
  margin: 10px auto; // 상하 마진은 그대로 유지하고 좌우 마진을 auto로 설정하여 가운데 정렬
  background-color: #f0f0f0; // 연한 회색 배경
  color: #2a2a2a; // 보다 어두운 회색을 사용하여 가독성 향상
  text-align: center;
  border-radius: 4px;
  font-size: 0.8rem;
  width: auto; // 넓이를 자동으로 설정
  max-width: 60%;
`;

const ScrollToBottomButton = styled.button`
  position: absolute;
  right: 20px;
  bottom: 130px; // 입력 폼의 상단에 위치하도록 bottom 값을 조정합니다.
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0.5;
  cursor: pointer;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')}; // isVisible prop에 따라 flex 또는 none을 적용합니다.
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &:hover {
    opacity: 0.7;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  min-width: 80px; // 최소 너비 설정
  overflow-y: auto;
  font-size: 0.8rem;
`;

const ChatMessage = ({ message, isCurrentUser }) => {
  const isNotification = message.type === 'notification';

  if (isNotification) {
    return (
      <NotificationMessage>
        {message.text}
      </NotificationMessage>
    );
  }

  return (
    <div>
      {!isCurrentUser && <Nickname>{message.nickname}</Nickname>}
      <MessageWrapper isCurrentUser={isCurrentUser}>
        <Message isCurrentUser={isCurrentUser}>{message.text}</Message>
        <Timestamp isCurrentUser={isCurrentUser}>
          {message.createdAt?.toDate().toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
          })}
        </Timestamp>
      </MessageWrapper>
    </div>
  );
};


const ChatRoomUI = () => {
  const { postId, chatRoomId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const currentUser = useSelector((state) => state.user.userInfo);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [showRoleSelection, setShowRoleSelection] = useState(false);
  const navigate = useNavigate();
  const [showSettingPanel, setShowSettingPanel] = useState(false); // 패널 표시 여부 제어
  const [isHost, setIsHost] = useState(false);
  const [maxSpeakersReached, setMaxSpeakersReached] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSpeakerLimitModal, setShowSpeakerLimitModal] = useState(false);
  const [currentSpeakerLimit, setCurrentSpeakerLimit] = useState(2);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);

  const handleShowSpeakerLimitModal = () => setShowSpeakerLimitModal(true);
  const handleHideSpeakerLimitModal = () => setShowSpeakerLimitModal(false);
  const [currentSpeakerCount, setCurrentSpeakerCount] = useState(0);
  const [viewerCount, setViewerCount] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [loading, setLoading] = useState(true);
  const [exists, setExists] = useState(true);
  const [speakers, setSpeakers] = useState([]);
  const [speakerDetails, setSpeakerDetails] = useState([]);
  const [liveSpeakers, setLiveSpeakers] = useState([]);



  useEffect(() => {
    const checkUserRoleAndHost = async () => {
      const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
      const chatRoomDoc = await getDoc(chatRoomRef);
  
      if (chatRoomDoc.exists()) {
        const chatRoomData = chatRoomDoc.data();
        // 호스트 ID 설정
        const isHost = chatRoomData.host === currentUser.userId;
        setIsHost(isHost);
  
        // 현재 사용자의 역할 확인 및 설정
        if (chatRoomData.speakers.includes(currentUser.userId)) {
          setUserRole('speaker');
        } else if (chatRoomData.viewers.includes(currentUser.userId)) {
          setUserRole('viewer');
        } else if (!isHost) { // 호스트가 아니라면 역할 선택 UI 표시
          setShowRoleSelection(true);
        }
      }
    };
  
    if (currentUser && postId && chatRoomId) {
      checkUserRoleAndHost();
    }
  }, [currentUser, postId, chatRoomId]);

  useEffect(() => {
  // 페이지에 진입할 때 발언자로 등록
  addLiveSpeaker(postId, chatRoomId, currentUser.userId)
    .then(() => console.log("Added as live speaker"))
    .catch(console.error);

  // 컴포넌트가 언마운트될 때 발언자 목록에서 제거
  return () => {
    removeLiveSpeaker(postId, chatRoomId, currentUser.userId)
      .then(() => console.log("Removed from live speakers"))
      .catch(console.error);
  };
}, [postId, chatRoomId, currentUser.userId]); 

  const handleRoleSelected = async (role) => {
    if (role === 'speaker') {
      await addSpeakerToChatRoom(postId, chatRoomId, currentUser.userId);
      setUserRole('speaker'); // 성공적으로 speaker로 등록 후 userRole 상태 업데이트
      await addLiveSpeaker(postId, chatRoomId, currentUser.userId);
      await notifyEntry(postId, chatRoomId, currentUser.userId, role, currentUser.nickname);
    } else if (role === 'viewer') {
      await addViewerToChatRoom(postId, chatRoomId, currentUser.userId);
      setUserRole('viewer'); // 성공적으로 viewer로 등록 후 userRole 상태 업데이트
      await notifyEntry(postId, chatRoomId, currentUser.userId, role, currentUser.nickname);
    }
    setShowRoleSelection(false); // 역할 선택 후 UI 숨기기
  };  
  
  useEffect(() => {
    const unsubscribe = subscribeToChatRoomMessages(postId, chatRoomId, setMessages);
    return () => unsubscribe();
  }, [postId, chatRoomId]);

  const scrollToBottom = useCallback(() => {
    console.log('scrollToBottom called, isAutoScrollEnabled:', isAutoScrollEnabled);
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isAutoScrollEnabled]);
  
  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.authorId !== currentUser.userId) {
        if (isAutoScrollEnabled) {
          scrollToBottom();
        }
      }
    }
  }, [messages, currentUser.userId, isAutoScrollEnabled, scrollToBottom]);

  useEffect(() => {
    // 컴포넌트가 언마운트될 때 실행될 로직
    return () => {
      if (currentUser && currentUser.userId) {
        removeViewerFromChatRoom(postId, chatRoomId, currentUser.userId)
          .catch((error) => console.error("Error removing viewer:", error));
      }
    };
  }, [postId, chatRoomId, currentUser]);

  useEffect(() => {
    // 컴포넌트 언마운트 시 실행
    return () => {
      if (currentUser && currentUser.userId && chatRoomId) {
        deleteChatRoomNotifications(currentUser.userId, chatRoomId);
      }
    };
  }, [currentUser, chatRoomId]);
  

  const handleScroll = ({ target }) => {
    const threshold = 1500;
    const isAtBottom = target.scrollHeight - target.scrollTop - target.clientHeight < threshold;
    setIsScrolling(true);
    
    if (!isAtBottom && isAutoScrollEnabled) {
      console.log('User is looking at past messages, auto-scroll is disabled');
      setIsAutoScrollEnabled(false);
    } else if (isAtBottom && !isAutoScrollEnabled) {
      console.log('User scrolled back to the bottom, auto-scroll is enabled');
      setIsAutoScrollEnabled(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsScrolling(false); // 1초 후에 isScrolling 상태를 false로 변경하여 스크롤바 숨김
    }, 1000); // 1초 후 스크롤바 숨기기

    return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머 제거
  }, [isScrolling]);

    

  useEffect(() => {
    const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
  
    const unsubscribe = onSnapshot(chatRoomRef, async (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setSpeakers(data.speakers || []);
        setCurrentSpeakerLimit(data.maxSpeakers);
        setCurrentSpeakerCount(data.speakers.length);
        setViewerCount(data.viewers.length);
        setMaxSpeakersReached(data.speakers.length >= data.maxSpeakers);
        setExists(true);
  
        // 발언자의 닉네임 가져오기
        const speakerDetails = await Promise.all(data.speakers.map(async (userId) => {
          const userInfo = await getUserInfo(userId);
          return userInfo ? { userId, nickname: userInfo.nickname } : { userId, nickname: "Unknown" };
        }));
        setSpeakerDetails(speakerDetails);  // State 업데이트
  
      } else {
        setExists(false);
      }
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, [postId, chatRoomId]);

  async function getUserInfo(userId) {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      return userSnap.data();
    }
    return null;
  }

  useEffect(() => {
    if (!loading && !exists) {
      alert("채팅방이 존재하지 않습니다.");
      navigate('/'); // Redirect to home if the chatroom does not exist
    }
  }, [loading, exists, navigate]);

  useEffect(() => {
    const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
  
    const unsubscribe = onSnapshot(chatRoomRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setLiveSpeakers(data.liveSpeakers || []);
      }
    });
  
    return () => unsubscribe();
  }, [postId, chatRoomId]);

  if (loading) {
    return <LoadingScreen />;
  }  
  
  // 역할 변경 로직을 담당하는 함수
  const toggleUserRole = async () => {
    if (userRole === 'viewer' && maxSpeakersReached) {
      alert("호스트가 지정한 발언자의 정원에 도달하여 역할을 변경할 수 없습니다.");
      setShowSettingPanel(false);
      return;
    }
  
    if (userRole === 'speaker') {
      // 발언자에서 관전자로 변경
      await runTransaction(db, async (transaction) => {
        const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
        const chatRoomDoc = await transaction.get(chatRoomRef);
        if (chatRoomDoc.exists()) {
          transaction.update(chatRoomRef, {
            speakers: arrayRemove(currentUser.userId),
            viewers: arrayUnion(currentUser.userId),
          });
        }
      }).then(async () => {
        setUserRole('viewer');
        await notifyRoleChange(postId, chatRoomId, 'speaker', 'viewer', currentUser.nickname);
        await removePostIdFromUser(currentUser.userId, postId);
        await removeLiveSpeaker(postId, chatRoomId, currentUser.userId);
      }).catch(error => {
        console.error("Transaction failed: ", error);
      });
    } else {
      // 관전자에서 발언자로 변경 시도
      await runTransaction(db, async (transaction) => {
        const chatRoomRef = doc(db, "posts", postId, "chatrooms", chatRoomId);
        const chatRoomDoc = await transaction.get(chatRoomRef);
        if (chatRoomDoc.exists()) {
          const speakers = chatRoomDoc.data().speakers;
          if (speakers.length < currentSpeakerLimit) {
            transaction.update(chatRoomRef, {
              speakers: arrayUnion(currentUser.userId),
              viewers: arrayRemove(currentUser.userId),
            });
          }
        }
      }).then(async () => {
        setUserRole('speaker');
        await notifyRoleChange(postId, chatRoomId, 'viewer', 'speaker', currentUser.nickname);
        await addPostIdToUser(currentUser.userId, postId);
        await addLiveSpeaker(postId, chatRoomId, currentUser.userId);
      }).catch(error => {
        console.error("Transaction failed: ", error);
      });
    }
  
    setShowSettingPanel(false);
  };
  

  const leaveChatRoom = () => {
    setShowLeaveConfirmation(true); // 나가기 전에 확인 모달을 표시
  };

  const confirmLeaveChatRoom = async () => {
    if (userRole === 'speaker') {
      await removeSpeakerFromChatRoom(postId, chatRoomId, currentUser.userId);
      await notifyExit(postId, chatRoomId, currentUser.nickname);
    }
    
    
    navigate('/'); // 채팅방 나가기 실행 후 홈으로 리다이렉트
    setShowLeaveConfirmation(false); // 확인 모달 숨기기
  };

  // 패널을 표시하는 버튼 또는 아이콘 클릭 핸들러
  const handleOpenSettingPanel = () => {
    setShowSettingPanel(true);
  };

  const handleCloseSettingPanel = () => {
    setShowSettingPanel(false);
  };

  const onChangeSpeakerLimit = async (newLimit) => {
    try {
      await setSpeakerLimit(postId, chatRoomId, newLimit);
      setCurrentSpeakerLimit(newLimit); // 상태 업데이트로 UI에 반영
      alert("발언자 인원 제한이 변경되었습니다.");
      handleCloseSettingPanel();
    } catch (error) {
      alert("발언자 인원 제한 변경에 실패했습니다.");
    } finally {
      handleHideSpeakerLimitModal();
    }
  };

  const onDeleteChatRoom = async () => {
    // 채팅방 삭제 로직을 여기에 구현합니다.
    try {
      await deleteChatRooms(postId, currentUser.userId);
      alert("채팅방이 성공적으로 삭제되었습니다.");
      navigate('/'); // 사용자를 홈으로 리디렉션
    } catch (error) {
      console.error("채팅방 삭제 중 오류가 발생했습니다:", error);
      alert("채팅방 삭제에 실패했습니다.");
    }
  };
  

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() && currentUser && userRole === 'speaker') { // userRole이 'speaker'인 경우에만 메시지 전송 허용
      const message = {
        text: newMessage,
        authorId: currentUser.userId,
        createdAt: serverTimestamp(),
      };
      await addMessageToChatRoom(postId, chatRoomId, message, currentUser.nickname);
      setNewMessage('');
      scrollToBottom();
      setIsAutoScrollEnabled(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSendMessage(e);
    }
  };

  // 사용자가 채팅방에 입장할 때 알림 생성
const notifyEntry = async (postId, chatRoomId, userId, role, nickname) => {
  const text = `${nickname}님이 ${role === 'speaker' ? '발언자로' : '관전자로'} 입장하였습니다.`;
  await addNotificationToChatRoom(postId, chatRoomId, text);
};

// 역할 변경 시 알림 생성
const notifyRoleChange = async (postId, chatRoomId, oldRole, newRole, nickname) => {
  const text = `${nickname}님이 ${newRole === 'speaker' ? '발언자' : '관전자'}로 역할을 변경하였습니다.`;
  if (oldRole !== newRole) {
    await addNotificationToChatRoom(postId, chatRoomId, text);
  }
};

// 사용자가 채팅방을 퇴장할 때 알림 생성
const notifyExit = async (postId, chatRoomId, nickname) => {
  const text = `${nickname}님이 퇴장하셨습니다.`;
  await addNotificationToChatRoom(postId, chatRoomId, text);
};

  const handleGoBack = () => {
    navigate(-1); // 이전 페이지로 돌아가기
  };

  const isSpeaker = userRole === 'speaker';
  
  return (
    <ChatContainer>
        {showRoleSelection && (
        <UserRoleSelection onRoleSelected={handleRoleSelected} maxSpeakersReached={maxSpeakersReached} />
      )}
        {showSettingPanel && (
        <SettingPanel 
        isHost={isHost}
        userRole={userRole}
        toggleUserRole={toggleUserRole}
        leaveChatRoom={leaveChatRoom}
        onClose={handleCloseSettingPanel}
        onChangeSpeakerLimit={onChangeSpeakerLimit}
        onDeleteChatRoom={() => setShowDeleteConfirmation(true)}
        onShowSpeakerLimitModal={handleShowSpeakerLimitModal} />
      )}
      <ChatRoomHeader>
        <ArrowBackButton src={ArrowBackIcon} alt="Back" onClick={handleGoBack} />
        <SpeakerViewerInfo>
          <InfoIcon src={GroupIcon} alt="Speaker Count" />
          <Dropdown>
            <Dropdown.Toggle as="span" id="dropdown-speakers-toggle" style={{ cursor: 'pointer' }}>
              {currentSpeakerCount}/{currentSpeakerLimit} 발언자
            </Dropdown.Toggle>
            <DropdownMenu>
              {speakerDetails
                .sort((a, b) => liveSpeakers.includes(b.userId) - liveSpeakers.includes(a.userId) || a.nickname.localeCompare(b.nickname))
                .map((speaker, index) => (
                  <Dropdown.Item key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    {liveSpeakers.includes(speaker.userId) ? <OnlineDot index={index} /> : <OfflineDot />}
                    {speaker.nickname || 'Unknown'}
                  </Dropdown.Item>
              ))}
            </DropdownMenu>
          </Dropdown>
          <InfoIcon src={ViewerIcon} alt="Viewer Count" style={{ marginLeft: '15px' }} /> {/* 여기서 아이콘 간격을 조금 더 넓힘 */}
          {viewerCount} 관전자
        </SpeakerViewerInfo>
        <StyledSettingIcon src={settingIconImg} alt="Settings" onClick={handleOpenSettingPanel} />
      </ChatRoomHeader>
        <MessagesContainer onScroll={handleScroll} isScrolling={isScrolling}>
            {messages.map((message, index) => (
                <ChatMessage
                key={index}
                message={message}
                isCurrentUser={message.authorId === currentUser.userId}
                />
            ))}
            <div ref={messagesEndRef} />
        </MessagesContainer>
        <div>
            <ChatInput
                newMessage={newMessage}
                setNewMessage={setNewMessage}
                handleSendMessage={handleSendMessage}
                isSpeaker={isSpeaker}
                handleKeyDown={handleKeyDown}
            />
            {/* 필요한 경우 추가 UI 구성 요소 */}
        </div>
        <ScrollToBottomButton onClick={scrollToBottom} isVisible={!isAutoScrollEnabled}>
        <FontAwesomeIcon icon={faChevronDown} />
      </ScrollToBottomButton>

        <SpeakerLimitModal
        show={showSpeakerLimitModal}
        onHide={handleHideSpeakerLimitModal}
        onSubmit={onChangeSpeakerLimit}
        currentLimit={currentSpeakerLimit}
        currentSpeakerCount={currentSpeakerCount}
        />
        <ConfirmationModal
        show={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={onDeleteChatRoom}
        title="채팅방 삭제 확인"
        body="채팅방을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다."
        />
        <ConfirmationModal
          show={showLeaveConfirmation}
          onClose={() => setShowLeaveConfirmation(false)}
          onConfirm={confirmLeaveChatRoom}
          title="채팅방 나가기 확인"
          body="정말로 채팅방을 나가시겠습니까?"
        />
  </ChatContainer>
  );
};

export default ChatRoomUI;