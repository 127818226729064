import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';
import AutoLinks from 'quill-auto-links';

Quill.register('modules/autoLinks', AutoLinks);
Quill.register('modules/blotFormatter', BlotFormatter);

export const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['image'],
    ],
  
    blotFormatter: {},
    autoLinks: true
  };