import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

const SelectionBox = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    width: 350px;
  }

  p {
    text-align: center;
  }
`;

const UserRoleSelection = ({ onRoleSelected, maxSpeakersReached, onExit }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    let timer;
    if (!showModal) {
      timer = setTimeout(() => {
        setShowModal(true); // 2초 후에 모달을 다시 보이도록 설정
      }, 5000);
    }

    return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 제거
  }, [showModal]);

  const handleExit = () => {
    if (onExit) {
      onExit();
    }
    navigate('/');
  };

  const handleRoleSelection = (role) => {
    onRoleSelected(role);
    setShowModal(false); // 역할 선택 시 모달 숨기기
  };

  if (!showModal) {
    return null; // showModal이 false이면 아무것도 렌더링하지 않음
  }

  return (
    <Overlay>
      <SelectionBox>
        {maxSpeakersReached ? (
          <>
            <p>호스트가 지정한 발언자의 정원에 도달하여 관전자로만 입장이 가능합니다. 관전자로 참여하시겠습니까?</p>
            <Button variant="secondary" onClick={() => handleRoleSelection('viewer')}>
              관전자로 참여
            </Button>
            <Button variant="danger" onClick={handleExit}>
              방 나가기
            </Button>
          </>
        ) : (
          <>
            <p>어떤 역할로 참여하시겠습니까?</p>
            <Button variant="primary" onClick={() => handleRoleSelection('speaker')}>
              발언자
            </Button>
            <Button variant="secondary" onClick={() => handleRoleSelection('viewer')}>
              관전자
            </Button>
          </>
        )}
      </SelectionBox>
    </Overlay>
  );
};

export default UserRoleSelection;
