import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification, removeNotification } from './notificationsSlice';
import { db } from '../../firebaseConfig';
import { collection, onSnapshot, query, where, orderBy } from 'firebase/firestore';

export function useNotificationListener() {
    const dispatch = useDispatch();
    const userId = useSelector(state => state.user.userInfo?.userId);

    useEffect(() => {
      if (userId) {
        const now = new Date();
        console.log('Setting up notification listener for new notifications.', now);

        // 현재 시간 이후에 생성된 알림만을 필터링
        const notificationsRef = collection(db, `users/${userId}/notifications`);
        const q = query(notificationsRef, where("createdAt", ">", now), orderBy("createdAt"));

        const unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added') {
              console.log('New notification:', change.doc.data());
              dispatch(addNotification({ id: change.doc.id, ...change.doc.data() }));
            }
          });
        });

        return () => {
          console.log('Unsubscribing from notifications.');
          unsubscribe();
        };
      }
    }, [userId, dispatch]);

    return null; // 이 컴포넌트는 UI를 렌더링하지 않습니다.
}