import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import styled from 'styled-components';

const CommentButton = styled(Button)`
  float: right;
  color: white;
  background-color: #343a40;
  border-color: #343a40;
  border-radius: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #495057;
    border-color: #495057;
    transform: scale(1.05);
  }

  &:active {
    background-color: #212529 !important;
    border-color: #212529 !important;
    transform: scale(0.95);
  }

  &:disabled {
    background-color: #6c757d;
    border-color: #6c757d;
    cursor: not-allowed;
    transform: none;
  }
`;


const ReplyForm = ({ postId, commentId, onReplySubmit, isUserLoggedIn }) => {
    const user = useSelector(state => state.user.userInfo); // Redux store에서 user 정보 가져오기
    const [reply, setReply] = useState('');
    const replyInputRef = useRef(null);
  
    useEffect(() => {
      // 로그인한 사용자에게만 자동 포커스를 설정합니다.
      if (isUserLoggedIn && replyInputRef.current) {
        replyInputRef.current.focus();
      }
    }, [isUserLoggedIn]);

    const handleSubmit = (e) => {
      e.preventDefault();
      if (isUserLoggedIn && reply.trim()) { // 로그인한 사용자만 답글을 등록할 수 있음
        onReplySubmit(commentId, { text: reply, authorId: user.userId, nickname: user.nickname });
        setReply('');
      }
    };
  
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows={3}
            ref={replyInputRef}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            placeholder={isUserLoggedIn ? "답글을 남겨주세요..." : "답글을 작성하려면 로그인 해주세요."}
            disabled={!isUserLoggedIn} // 로그인하지 않은 사용자는 입력 폼을 사용할 수 없음
          />
        </Form.Group>
        <div className="d-flex justify-content-end">
        <CommentButton type="submit" className="mt-2" disabled={!isUserLoggedIn}>등록</CommentButton>
        </div>
      </Form>
    );
};

export default ReplyForm;
