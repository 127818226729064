import { app, db } from '../firebaseConfig'
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { updateNickname as updateNicknameAction } from '../features/userSlice';



const auth = getAuth(app);

// 회원가입
const signUp = async (email, password, nickname = '') => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    console.log('회원가입 성공', userCredential.user);

    const finalNickname = nickname || email.split('@')[0];
    await createUserProfile(userCredential.user.uid, email, finalNickname);

    return true; // 회원가입 성공 시 true 반환
  } catch (error) {
    console.error('회원가입 실패:', error);
    console.error('Error code:', error.code);
    console.error('Error message:', error.message);

    let errorMessage = '회원가입 중 오류가 발생했습니다';

    switch (error.code) {
      case 'auth/email-already-in-use':
        errorMessage = '이미 사용중인 이메일입니다';
        break;
      case 'auth/invalid-email':
        errorMessage = '이메일 주소가 유효하지 않습니다';
        break;
      case 'auth/operation-not-allowed':
        errorMessage = '이메일/비밀번호 회원가입이 활성화되지 않았습니다';
        break;
      case 'auth/weak-password':
        errorMessage = '비밀번호가 너무 약합니다';
        break;
    }

    throw new Error(errorMessage); // 에러 메시지를 포함하여 에러를 throw
  }
};


const createUserProfile = async (userId, email, nickname, lastLogin = new Date()) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      // 사용자 문서가 존재하지 않는 경우에만 프로필 생성
      await setDoc(userDocRef, {
        userId,
        email,
        nickname,
        participatedPosts: [], // 사용자가 참여한 게시글 ID 목록
        createdAt: new Date(),
        lastLoginTime: lastLogin // 로그인 시간 추가
      });
      console.log("User profile created with nickname: ", nickname);
      window.location.reload();
    } else {
      // 이미 존재하는 사용자의 lastLoginTime 업데이트
      await updateDoc(userDocRef, {
        lastLoginTime: lastLogin
      });
      console.log("User profile updated with new login time.");
    }
  } catch (error) {
    console.error("Error creating/updating user profile:", error);
  }
};

// 로그인
const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log('로그인 성공', userCredential.user);

    // 로그인 성공 후, lastLoginTime 업데이트
    const lastLoginTime = new Date();
    const userDocRef = doc(db, "users", userCredential.user.uid);

    // Firestore 문서 업데이트
    await updateDoc(userDocRef, {
      lastLoginTime: lastLoginTime
    });
    console.log("Last login time updated for user:", userCredential.user.email);

    return true;
  } catch (error) {
    console.error('로그인 실패:', error);
    console.error('Error code:', error.code);  // 에러 코드 로깅 추가
    console.error('Error message:', error.message);
    // 에러 코드에 따른 에러 메시지 변환
    switch (error.code) {
      case 'auth/wrong-password':
        return '비밀번호가 잘못되었습니다';
      case 'auth/user-not-found':
        return '해당 사용자를 찾을 수 없습니다';
      case 'auth/invalid-email':
        return '이메일 주소가 유효하지 않습니다';
      case 'auth/invalid-credential':
        return '유효하지 않은 계정입니다';
      default:
        return '로그인 중 오류가 발생했습니다';
    }
  }
};


const logout = async () => {
  try {
    await signOut(auth);
    console.log('로그아웃 성공');
  } catch (error) {
    console.error('로그아웃 실패:', error);
  }
};

// Google 로그인
const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const lastLoginTime = new Date();

    // 사용자의 닉네임 결정: Google 프로필의 이름 사용, 없으면 이메일에서 추출
    const nickname =user.email.split('@')[0];

    // createUserProfile 함수를 호출하여 프로필 생성 또는 확인
    await createUserProfile(user.uid, user.email, nickname, lastLoginTime);

  } catch (error) {
    console.error('Google 로그인 실패:', error);
  }
};

// 닉네임 업데이트 함수
const updateNickname = async (userId, newNickname, dispatch) => {
  if (!userId) {
    console.error('No user ID provided to update nickname');
    return;
  }

  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      // Firestore 문서 업데이트
      await updateDoc(userDocRef, {
        nickname: newNickname
      });

      // Redux 스토어의 닉네임 업데이트
      dispatch(updateNicknameAction({ userId, nickname: newNickname }));

      console.log("Nickname updated successfully to:", newNickname);
    } else {
      console.error('User profile does not exist. Unable to update nickname.');
    }
  } catch (error) {
    console.error("Error updating nickname:", error);
  }
};

// 사용자 인증 상태 리스너
onAuthStateChanged(auth, (user) => {
  if (user) {
    // 사용자가 로그인한 상태
    console.log('User is signed in', user);
  } else {
    // 사용자가 로그아웃한 상태
    console.log('User is signed out');
  }
});

export { signUp, signIn, signInWithGoogle, logout, updateNickname };