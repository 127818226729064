import React, { useEffect } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { removeNotification } from './notificationsSlice';
import { deleteNotification } from './deleteNotification';

const GlobalNotificationToast = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications.items);
    const user = useSelector((state) => state.user.userInfo);
  
    useEffect(() => {
      // 예시: 첫 번째 알림에 대한 자동 종료 타이머 설정
      if (notifications.length > 0) {
        const timer = setTimeout(() => {
          dispatch(removeNotification(notifications[0].id));
        }, 5000);
        return () => clearTimeout(timer);
      }
    }, [notifications, dispatch]);
  

    const handleNavigation = async (notification) => {
        await deleteNotification(user.userId, notification.id);
        navigate(notification.destinationPath);
        dispatch(removeNotification(notification.id));
      };

    const toastContainerStyle = {
        position: 'fixed', // 항상 고정 위치
        bottom: '20px',   // 하단에서 20px 위
        right: '20px',    // 우측에서 20px 왼쪽
        zIndex: 1050,     // 다른 요소들 위에 표시
        maxWidth: '300px' // 최대 너비 제한
      };

    // 채팅방 페이지에서는 토스트를 렌더링하지 않습니다.
    if (location.pathname.includes('/chatroom') || !user) { // 로그인하지 않은 상태에서는 토스트를 렌더링하지 않음
        return null;
    }
  
    return (
      <ToastContainer position="bottom-end" className="p-3"style={toastContainerStyle}>
        {notifications.map(notification => (
          <Toast key={notification.id} onClose={() => dispatch(removeNotification(notification.id))} delay={5000} autohide>
            <Toast.Header>
              <strong className="mr-auto">New Notification</strong>
            </Toast.Header>
            <Toast.Body onClick={() => handleNavigation(notification)}>
              {notification.message}
            </Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    );
  };
  
  export default GlobalNotificationToast;